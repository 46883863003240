export default {
    fr: {
        'x-product-option-size': 'Choisir la taille',
        'x-product-description': 'Détails du produit',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Votre vélo woom',
                label: 'Sélectionnez votre vélo woom',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: "TVA incluse, hors frais d'expédition",
            color: 'Couleur',
            fits: 'Compatible avec',
            position: 'Position',
            edition: 'Version',
            language: 'Langue',
            size: 'Taille',
            nameField: 'Name',
            sizes: {
                guide: 'Informations sur les tailles',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom ORIGINAL 1+ (de nov. 2020), 2, 3',
                    m: 'ORIGINAL 4, 5 et OFF + OFF AIR 4',
                    l: 'ORIGINAL 6 et OFF + OFF AIR 5, 6 et UP 5, 6',
                },
            },
            variant: {
                front: 'avant',
                rear: 'arrière',
                left: 'gauche',
                right: 'droite',
                en: 'Anglais',
                de: 'Allemand',
            },
            estimateDeliveryDate: 'Date de livraison prévue',
            // shippingPeriod: 'Actuellement en rupture de stock, mais précommande possible. Date de livraison prévue : {date}',
            quantity: 'Un article en stock. | {n} articles en stock.',
            reviews: "Pas d'évaluation | Une évaluation | {n} évaluations",
            estimatedShipping: "Frais d'expédition estimés",
            estimatedTaxes: 'Taxes à prévoir',
            estimatedTotal: 'Montant total attendu',
            availability: {
                not_for_sale: "Ce produit n'est pas disponible à l'achat dans cette région.",
                preorder: {
                    short: 'Actuellement en rupture de stock, mais précommande possible.',
                    long: 'Actuellement en rupture de stock, mais précommande possible.\nDate de livraison prévue : {date}',
                    line: 'Date de livraison prévue : {date}',
                },
                comingsoon: {
                    short: 'Actuellement en rupture de stock.',
                    long: `Actuellement en rupture de stock.
                        Disponible à la commande dans environ {date}.`,
                },
                not_in_this_region: "Ce produit n'est pas disponible dans cette région.",
                notify_me: 'Indiquez votre adresse e-mail pour être le premier à savoir quand cet article est de nouveau en stock.',
                out_of_stock: 'Indisponible',
                weeks: '{period} semaines',
            },
            detail: {
                bike: 'Taille ',
                weight: 'Poids ',
                age: 'Âge ',
                height: 'Taille ',
                features: 'Particularités ',
                years: 'ans',
            },
            geometry: {
                height: 'Hauteur',
                label: 'Données géométriques',
                wheel: {
                    size: 'Taille des pneus/roues',
                    base: 'Empattement',
                },
                handlebar: {
                    height: 'Hauteur du cockpit/poignée',
                    adjust: 'Réglage du guidon',
                },
                steering: {
                    angle: 'Angle de chasse',
                },
                top_tube: {
                    horizontal: 'Longueur du tube supérieur horizontal',
                },
                seat_tube: {
                    angle: 'Angle du tube de selle',
                },
                saddle: {
                    height: {
                        min: 'Hauteur de selle la plus basse',
                        max: 'Hauteur de selle maximale',
                    },
                },
                crank: {
                    length: 'Longueur du bras de pédalier',
                },
                standover: {
                    height: "Seuil d'enjambement",
                },
            },
            specs: {
                label: 'Spécifications',
            },
            configurator: {
                added: 'Check! Le {product} est bien arrivé dans votre panier.',
                cart: 'Accéder au panier',
                extras: 'Ces produits peuvent également vous intéresser',
            },
            matchingProducts: 'Ces produits sont compatibles',
        },
        free: 'Gratuit',
        tip: 'Conseil',
        cart: {
            add: 'Ajouter au panier',
            add_preorder: 'Ajouter au panier',
            add_short: 'Ajouter',
            options: 'Options',
            yourCart: 'Votre panier',
            viewCart: "Voir le panier et finaliser l'achat",
            tell_me_first: 'Informez-moi en premier !',
            checkout: 'Paiement',
            reviewCart: 'Vérifier le panier',
            empty: 'Votre panier est vide.\nTrouvez maintenant le bon vélo pour votre enfant !',
            total: 'Montant total',
            subtotal: 'Sous-total',
            items: 'Vide | 1 article | {n} articles',
            shipping: 'Expédition',
            additionalShippingCharges:
                "Les articles surdimensionnés peuvent être soumis à des frais d'expédition supplémentaires lors du passage à la caisse.",
            needHelp: "Besoin d'aide ?",
            productUnavailable: 'Nous sommes désolés, ce produit est actuellement indisponible.',
            maxQuantityReached: 'Désolé, la quantité maximale disponible pour ce produit est {quantity}.',
            limitedQuantityAvailable:
                'Vous avez ajouté {currentQuantity} à votre panier, mais actuellement seules {quantityAvailable} sont disponibles.',
            couldNotAdd: "Le produit n'a pas pu être ajouté au panier.",
            limit: 'Limite de commande atteinte',
        },
        forms: {
            validation: {
                required: 'Ce champ ne doit pas être vide.',
                email: 'Une adresse e-mail valide est nécessaire.',
                right: 'The following fields contain errors: {fields}',
                age: 'Please provide us with a valid age.',
                number: 'A number is required in this field.',
                select: 'Veuillez faire votre choix.',
                file: 'Please select a file for upload.',
                consent: 'We would need your consent to proceed.',
            },
        },
        landing: {
            selector: {
                country: 'Pays',
                language: 'Langue',
                button: 'Visiter le site web',
            },
        },
        bike: {
            bike: 'Vélo',
            years: 'ans',
            child: 'Enfant',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Découvrez les vélos woom ORIGINAL',
                off_off_air: 'Découvrir les VTT',
                up: 'Découvrir les VTT électriques',
                now: 'Découvrez le woom NOW',
            },
        },
        bikefinder: {
            link: 'Choisir le bon vélo.',
            years: 'Années',
            how_it_works: 'Comment cela fonctionne-t-il ?',
            next: 'Suivant',
            back: 'Précédent',
            see_results: 'Afficher les résultats',
            back_to_start: 'recommencer à zéro',
            share: 'Partager les résultats',
            height: {
                small: 'petit',
                medium: 'moyenne',
                large: 'grand',
            },
            skill: {
                beginner: 'Débutant',
                advanced: 'Avancé',
            },
            gender: {
                boy: 'boy',
                girl: 'girl',
            },
        },
        article: {
            readmore: 'En savoir plus',
        },
        footer: {
            newsletter: {
                'sign-me-up': "S'abonner à la newsletter",
            },
            copyright: 'Copyright © 2021 woom GmbH. Tous droits réservés.',
            contact: 'Contact',
        },
        error: {
            homepage: "Revenir à l'accueil",
            404: `Désolé, nous ne trouvons pas la page demandée.
    Il se peut que le lien que vous avez utilisé soit rompu ou que la page ait changé de place.`,
            '5xx': `Uuuuups!
    There is something wrong with our servers.
    We will be back soon.`,
        },
        campaign: {
            gdg: {
                headline: `Faites du vélo, mangez des glaces,
            collectez des kilomètres et faites une bonne action!`,
                headlineMillion: 'Faites du vélo, mangez des glaces et remportez de superbes prix!',
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Participer!',
                woomSupportsUnicef: 'soutient',
                unicefInfo: "L'UNICEF ne soutient aucun(e) entreprise, marque, produit ou service.",
            },
            nowLaunch: {
                title: 'Tiens-toi prêt.\nPour un vélo qui va tout changer.',
                ctaLabel: 'En savoir plus',
                ctaLink: 'Sabonner-a-la-newsletter',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Jours',
                    },
                    hours: {
                        singular: 'Heures',
                        plural: 'Heures',
                    },
                    minutes: {
                        singular: 'Minutes',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Secondes',
                        plural: 'Secondes',
                    },
                },
            },
        },
    },
};
