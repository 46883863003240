import { AppStateType } from '~~/common/types/appState.type';

const defaultAppStateValue: AppStateType = {
    TEST: 'unknown',
    TEST1234: 'unknown',
};

const appStateKey = 'global-app-state';

export default defineNuxtPlugin((nuxtApp) => {
    const appState = useState<AppStateType>(appStateKey, () => {
        return defaultAppStateValue;
    });

    nuxtApp.provide('appState', (): Ref<AppStateType> => {
        return appState;
    });
});
