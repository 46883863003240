<script setup lang="ts">
    const route = useRoute();

    console.log('app.vue route.params', route.params);
    // const { $apolloHelpers } = useNuxtApp();

    // if (!!$apolloHelpers?.getToken) {
    //     const token = $apolloHelpers.getToken();
    //     //     console.log('token', token);
    // }
    // const store = useTestStore();
    // const requestEvent = useRequestEvent();
    // if (process.server) {
    //     const headers = requestEvent.node.req.headers;
    //     console.log({ requestEvent, headers });
    // }

    /*
    export function createRouter() {
    // router config changes depending if it's rendered server side or client/browser side
    let routes = null
    if (process.client) {
      // check for subdomain setup
      const fe = new URL(document.location.href)
      const compare = new URL(process.env.BASE_URL)
      if (fe.hostname !== compare.hostname && fe.hostname.indexOf(compare.hostname) > 0) {
        const type = fe.hostname.substring(0, fe.hostname.length - (compare.hostname.length + 1))
        switch (type) {
        case 'www':
        {
          console.log('landing page routes')
          break
        }
        default:
        {
          // console.log('region page', type)
          routes = [
            // cart
            {
              name: ROUTE_SHOPPING_CART,
              path: '/cart/:checkout?',
              component: () => interopDefault(
                import('~/controller/ShoppingCart')),
              meta: { requiresAuth: false },
            },
            {
              name: 'policy',
              path: '/:lang([a-zA-Z]{2})?/policy',
              component: () => interopDefault(
                import('~/controller/CookiePolicy')),
              meta: { requiresAuth: false },
            },
            {
              name: 'home',
              path: '/:lang([a-zA-Z]{2})?/:slug*',
              component: () => interopDefault(
                import('../controller/PageController')),
              meta: { requiresAuth: false, multidomain: type, defaultLanguage: 'de', languages: ['en', 'de'] },
            },
          ]
        }
        }
      }
    }
    if (!routes) {
      // either server side or without multidomain
      routes = [{
        name: ROUTE_SHOPPING_CART,
        path: '/:langCountry/cart/:checkout?',
        component: () => interopDefault(
          import('~/controller/ShoppingCart')),
        meta: { requiresAuth: false },
      },
    //   {
    //     name: 'policy',
    //     path: '/:lang([a-zA-Z]{2})/policy',
    //     component: () => interopDefault(
    //       import('~/controller/CookiePolicy')),
    //     meta: { requiresAuth: false },
    //   },
    //   {
    //     name: 'content-switch',
    //     path: '/:lang([a-zA-Z]{2})/:slug',
    //     component: () => interopDefault(
    //       import('../controller/ContentSwitch')),
    //     meta: { requiresAuth: false },
    //   },
      {
        name: 'preview-product',
        path: '/preview/product/:cms/:lang/:id',
        component: () => interopDefault(
          import('../controller/ProductPreview')),
        meta: { requiresAuth: false },
      },
      {
        name: 'preview-home',
        path: '/preview/:cms/:lang/:id',
        component: () => interopDefault(
          import('../controller/ContentPreview')),
        meta: { requiresAuth: false },
      },
      {
        name: 'language-switch',
        path: '/start/:lang([a-zA-Z]{2})?',
        component: () => interopDefault(
          import('../controller/LanguageSwitcher')),
        meta: { requiresAuth: false },
      },
    //   {
    //     name: 'home',
    //     path: '/:langCountry([a-zA-Z]{2}_[a-zA-Z0-9]{2,3})/:slug*',
    //     component: () => interopDefault(
    //       import('../controller/PageController')),
    //     meta: { requiresAuth: false },
    //   },
    //   {
    //     name: 'root',
    //     path: '/:lang([a-zA-Z]{2})?',
    //     component: () => interopDefault(
    //       import('../controller/LanguageSwitcher')),
    //     meta: { requiresAuth: false },
    //   },
      {
        name: 'unknown',
        path: '/*',
        component: () => interopDefault(
          import('../controller/LanguageSwitcher')),
        meta: { requiresAuth: false },
      },
      ]
    }
    const router = new Router({
      mode: 'history',
      routes,
  
      scrollBehavior(_to, _from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
      },
    })
    return router
  }
    */
    const layout = 'onboarding';
</script>

<template>
    <div>
        <!-- <NuxtWelcome /> -->
        <!-- <NuxtPage /> -->
        <NuxtLayout></NuxtLayout>
    </div>
</template>

<style>
    :root {
        --color-white: #ffffff;
        --color-white: #ffffff;
        --text-color-white: #222222;
        --color-black: #222222;
        --text-color-black: #ffffff;
        --color-contrast-shadow: rgba(0, 0, 0, 0.1);
        --footer-color-contrast: var(--color-black);
        --footer-text-color-contrast: var(--color-white);
        --color-red: #eb0613;
        --footer-color: #f5f5f5;
        --footer-text-color: #787878;
        --color-red: #eb0613;
    }

    .woom-light {
        --background-color: white;
        --color: #222;
        --color-light: white;
        --text-color-light: #222;
        --color-dark: #000;
        --color-primary: red;
        --logo-color: red;
        --text-color: var(--color);
        --text-color-primary: white;
        --color-contrast: var(--color-black);
        --text-color-contrast: var(--color-white);
        --footer-color: #f5f5f5;
        --footer-text-color: #787878;
        --footer-color-contrast: var(--color-black);
        --footer-text-color-contrast: var(--color-white);
        --color-seperator: grey;
        --color-divider: #ccc;
        --color-light-grey: #f5f5f5;
        --text-color-light-grey: #222222;
        --color-grey: grey;
        --text-color-grey: var(--color-black);
        --color-contrast-shadow: rgba(0, 0, 0, 0.1);
        --color-green: rgb(139, 194, 74, 0.2);
        --text-color-green: var(--color-black);
        --color-purple: #bf0085;
        --text-color-purple: var(--color-white);
        --color-red: #eb0613;
        --text-color-red: var(--color-white);
        --color-yellow: #fab900;
        --text-color-yellow: #222222;
        --color-black: #222222;
        --text-color-black: var(--color-white);
        --color-green: #279c38;
        --text-color-green: var(--color-white);
        --color-label-grey: #787878;
        --text-color-label-grey: var(--color-white);
        --color-border-grey: #d8d8d8;
    }
    .woom-dark {
        --background-color: #222222;
        --color: #ffffff;
        --color-light: #ccc;
        --text-color-light: #555555;
        --color-dark: #555;
        --text-color-dark: #fff;
        --color-primary: red;
        --logo-color: white;
        --text-color: var(--color);
        --text-color-primary: white;
        --color-contrast: var(--color-white);
        --text-color-contrast: var(--color-black);
        --footer-color: #f5f5f5;
        --footer-text-color: #d6d6d6;
        --footer-color-contrast: var(--color-black);
        --footer-text-color-contrast: var(--color-white);
        --color-seperator: grey;
        --color-divider: black;
        --color-light-grey: #f5f5f5;
        --text-color-light-grey: #222222;
        --color-grey: grey;
        --text-color-grey: var(--color-black);
        --color-contrast-shadow: rgba(255, 255, 255, 0.1);
    }
    .woom-app {
        max-width: 100vw;
        @apply min-h-[100vh];
    }
    .page-container {
        min-height: calc(100vh - var(--nav-bar-height));
        max-width: 100vw;
        position: relative;
        z-index: 1;
    }
</style>
