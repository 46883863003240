// Google Tag Manager Class to be injected
// import Cookies from 'js-cookie'

import { RouteLocationNormalized } from '~/.nuxt/vue-router';
import {
    CheckoutMutation_ManageCheckout_Checkout,
    CheckoutMutation_ManageCheckout_Checkout_Extended,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems,
    CollectionQuery_Collection_Products_Items,
    EcommerceListingClickProductType,
    PageByUrlQuery_PageByUrl_Page,
    PageByUrlQuery_PageByUrl_Page_Bundle_Products,
} from '~/common/types/nestedGraphqlTypes';

import { GTMModuleOptions } from '~/modules/gtm';

const searchEngines = [
    'www.google',
    'www.bing',
    'www.yandex',
    'search.yahoo',
    'duckduckgo',
    'www.ask',
    'www.search',
    'www.info',
    'www.baidu',
    'search.aol',
    'www.dogpile',
];
// let firstCall = false

type CTXType = any; //

export class GTM {
    ctx: CTXType;
    options: GTMModuleOptions;
    constructor(ctx: CTXType, options: GTMModuleOptions) {
        this.ctx = ctx;
        this.options = options;
    }

    init() {
        // @ts-ignore
        window[this.options.layer] = window[this.options.layer] || [];

        this.pushEvent({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
        });

        if (this.options.pageTracking && (!this.options.respectDoNotTrack || !this.hasDNT())) {
            // console.log('do page tracking') // eslint-disable-line no-console
            this.initPageTracking();
        }
        /*
    if (!firstCall) {
      firstCall = true
      window[this.options.layer].push({ event: 'gtm.js', pageType: 'PageView', pageUrl: path, routeName: name })
    }
    */
    }

    ecommerceClear() {
        this.pushEvent({ ecommerce: null, event: 'ecommerceClear' });
    }

    numericPrice(p: number | string) {
        if (typeof p === 'string') {
            try {
                return Number.parseFloat(p);
            } catch (err) {
                return p;
            }
        }
        return p;
    }

    /**
     * returns the categories as an array
     */
    getCategories(cat: string | null | undefined) {
        if (!cat) {
            return ['', '', '', '', ''];
        }
        const c = cat.toLowerCase();
        if (['original', 'now', 'up', 'off'].includes(c)) {
            return ['Bikes', cat, '', '', ''];
        }
        return [cat, '', '', '', ''];
    }

    ecommerceProductList(listId: string, listLabel: string, products: CollectionQuery_Collection_Products_Items) {
        console.log('list id', listId, listLabel, products);
        if (Array.isArray(products) && products.length > 0) {
            const items = [];
            for (const product of products) {
                const price = product.priceRange?.minVariantPrice.amount || '';
                const currency = product.priceRange?.minVariantPrice.currencyCode || 'EUR';
                const variant = product.singleBuy || null;
                // console.log('product', product);
                const cats = this.getCategories(product.productType);
                items.push({
                    item_name: product.shopTitle, // Name or ID is required.
                    item_id: variant?.sku,
                    currency,
                    price: this.numericPrice(price),
                    index: items.length,
                    item_brand: 'woom',
                    item_category: cats[0],
                    item_category2: cats[1],
                    item_category3: cats[2],
                    item_category4: cats[3],
                    item_category5: cats[4],
                    item_variant: variant?.title === 'Default Title' ? '' : variant?.title,
                    item_list_id: listId,
                    item_list_name: listLabel,
                    quantity: 1,
                });
            }
            this.ecommerceClear();
            this.pushEvent({
                event: 'view_item_list',
                ecommerce: {
                    item_list_id: listId,
                    item_list_name: listLabel,
                    items,
                },
            });
        }
    }

    parseTextPrice(price: string) {
        let bundlePrice = price.replace(/[^0-9.,]/gi, '');
        const dot = bundlePrice.indexOf('.');
        const comma = bundlePrice.indexOf(',');
        if (comma > 0) {
            if (dot > 0 && dot < comma) {
                bundlePrice = bundlePrice.replace(/[.]/gi, '').replace(/[,]/, '.');
            } else if (dot > 0 && dot > comma) {
                bundlePrice = bundlePrice.replace(/[,]/gi, '');
            } else {
                bundlePrice = bundlePrice.replace(/[,]/gi, '.');
            }
        }
        try {
            const ret = Number.parseFloat(bundlePrice);
            return Number.isNaN(ret) ? 0 : ret;
        } catch (err) {
            return 0;
        }
    }

    ecommerceBundlePage(page: PageByUrlQuery_PageByUrl_Page | null, products: PageByUrlQuery_PageByUrl_Page_Bundle_Products) {
        // console.log('comm bundle page', page, products);
        const price = this.parseTextPrice(page?.bundle?.price || '');

        // console.log('price is', price);

        if (page && page.bundle) {
            // parse the price label from bundle

            // console.log('ecom gtm page view', page, product, variant)
            if (products && products.length > 0) {
                const first = products[0];
                const currency = first.variants.items.find((x: any) => x).price.currencyCode || 'USD';
                // console.log('first product', first, currency)
                const cats = ['Bundle', '', '', '', ''];
                const shid = page.bundle.code;
                this.ecommerceClear();
                // this will be a single product call for the bundle
                let img = null;
                const imgBase = page.bundle.cartImage?.data.image || '';
                if (imgBase !== '') {
                    img = `${page.bundle.cartImage?.data.baseUrl}${imgBase}`;
                }
                const bundleLabel = page.bundle.label || 'Undefined Bundle';

                this.pushEvent({
                    event: 'productView',
                    ecommerce: {
                        currencyCode: currency,
                        detail: {
                            actionField: { list: 'Product Detail Page' }, // Optional list property.
                            products: [
                                {
                                    name: bundleLabel, // Name or ID is required.
                                    id: shid,
                                    price: `${price.toFixed(2)}`,
                                    brand: 'woom',
                                    category: 'Bundle',
                                    variant: '',
                                    image: img,
                                    categories: [],
                                },
                            ],
                        },
                    },
                    shopProductId: shid,
                });

                this.ecommerceClear();
                // now ga4 - this will contain the breakdown with all bundle products
                const items = [];
                let idx = 0;
                for (const product of products) {
                    const variant = product.variants.items.find((x: any) => x) || null;
                    // console.log('type is', product, variant.productType)
                    const cats = this.getCategories(variant.productType);
                    if (variant) {
                        items.push({
                            item_name: product.shopTitle, // Name or ID is required.
                            item_id: variant.sku,
                            currency,
                            price: this.numericPrice(variant.price.amount),
                            index: idx,
                            bundle_name: bundleLabel,
                            bundle_price: price,
                            barcode: variant.barcode,
                            item_brand: 'woom',
                            item_category: cats[0],
                            item_category2: cats[1],
                            item_category3: cats[2],
                            item_category4: cats[3],
                            item_category5: cats[4],
                            item_variant: '',
                        });
                        idx += 1;
                    }
                }
                this.pushEvent({
                    event: 'view_item',
                    ecommerce: {
                        currency,
                        value: price,
                        items,
                    },
                    shopProductId: shid,
                });
            }
        }
    }

    ecommerceProductPage(page: PageByUrlQuery_PageByUrl_Page | null) {
        if (page && page.product) {
            const product = page.product;
            const price = product.priceRange?.minVariantPrice.amount || '';
            const currency = product.priceRange?.minVariantPrice.currencyCode || 'EUR';
            const variant = product.variants?.items?.find((x: any) => x) || null;

            // console.log('ecom gtm page view', page, product, variant)
            if (product && variant) {
                this.ecommerceClear();
                const cats = this.getCategories(product.productType);
                // does not exist - but sku does on variant?
                // let shid = product.sku
                let shid = variant.sku;
                if (product.nativeId) {
                    shid = Buffer.from(product.nativeId, 'base64').toString('utf8');
                    shid = shid.substring(22);
                    // console.log('product', product.nativeId, shid)
                }
                this.pushEvent({
                    event: 'productView',
                    ecommerce: {
                        currencyCode: currency,
                        detail: {
                            actionField: { list: 'Product Detail Page' }, // Optional list property.
                            products: [
                                {
                                    name: product.shopTitle, // Name or ID is required.
                                    id: variant.sku,
                                    price,
                                    brand: 'woom',
                                    category: product.productType,
                                    variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
                                    image: product.shopImage,
                                    categories:
                                        product.collections && product.collections.length > 0
                                            ? product.collections.map((c) => {
                                                  return c?.handle;
                                              })
                                            : [],
                                },
                            ],
                        },
                    },
                    shopProductId: shid,
                });
                this.ecommerceClear();
                // now ga4
                this.pushEvent({
                    event: 'view_item',
                    ecommerce: {
                        currency,
                        value: this.numericPrice(price),
                        items: [
                            {
                                item_name: product.shopTitle, // Name or ID is required.
                                item_id: variant.sku,
                                currency,
                                price: this.numericPrice(price),
                                index: 0,
                                barcode: variant.barcode,
                                item_brand: 'woom',
                                item_category: cats[0],
                                item_category2: cats[1],
                                item_category3: cats[2],
                                item_category4: cats[3],
                                item_category5: cats[4],
                                item_variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
                            },
                        ],
                    },
                });
            }
        }
    }

    logData(d: any) {
        console.log(JSON.stringify(d, null, '  '));
    }

    // product parameter here is going to be messy later...
    ecommerceListingClick(product: EcommerceListingClickProductType, position = 0, listId = 'undefined', listLabel = 'undefined List') {
        // console.log('ecom gtm click', product, position + 1)
        const price = product.priceRange?.minVariantPrice.amount || '';
        const currency = product.priceRange?.minVariantPrice.currencyCode || 'EUR';
        if (product && product.singleBuy) {
            this.ecommerceClear();
            this.pushEvent({
                event: 'productClick',
                ecommerce: {
                    currencyCode: currency,
                    click: {
                        actionField: { list: 'Search Results' }, // Optional list property.
                        products: [
                            {
                                name: product.shopTitle, // Name or ID is required.
                                id: product.singleBuy.sku,
                                price,
                                brand: 'woom',
                                category: product.productType,
                                variant: product.singleBuy.title === 'Default Title' ? '' : product.singleBuy.title,
                                position,
                            },
                        ],
                    },
                },
            });
            this.ecommerceClear();
            const cats = this.getCategories(product.productType);
            this.pushEvent({
                event: 'select_item',
                ecommerce: {
                    item_list_id: listId,
                    item_list_name: listLabel,
                    items: [
                        {
                            item_name: product.shopTitle, // Name or ID is required.
                            item_id: product.singleBuy.sku,
                            currency,
                            price: this.numericPrice(price),
                            index: 0,
                            item_brand: 'woom',
                            item_category: cats[0],
                            item_category2: cats[1],
                            item_category3: cats[2],
                            item_category4: cats[3],
                            item_category5: cats[4],
                            item_variant: product.singleBuy.title,
                            item_list_id: listId,
                            item_list_name: listLabel,
                        },
                    ],
                },
            });
        }
    }

    processCartItem(li: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem, idx: number, quantity: number) {
        // console.log('li is', li)
        const cats = this.getCategories(li.variant?.productType);
        let bundleName = '';
        let bundlePrice = 0;
        const bundlePriceDef = li.customAttributes.find((p: any) => p.key === '_bundle_price');
        if (bundlePriceDef) {
            bundlePrice = this.parseTextPrice(bundlePriceDef.value);
        }
        const bundleNameDef = li.customAttributes.find((p: any) => p.key === 'Bundle');
        if (bundleNameDef) {
            bundleName = bundleNameDef.value;
        }
        let discount = 0;
        if (Array.isArray(li.discountAllocations)) {
            for (const d of li.discountAllocations) {
                if (d.allocatedAmount && d.allocatedAmount.amount) {
                    const p = this.parseTextPrice(d.allocatedAmount.amount);
                    if (p) {
                        discount += p / (li.quantity || 1);
                    }
                }
            }
            discount = Math.round(discount * 100) / 100;
        }
        return {
            item_name: li.shopTitle, // Name or ID is required.
            item_id: li.variant?.sku,
            currency: li.variant?.price?.currencyCode,
            price: this.numericPrice(li.variant?.price?.amount || ''),
            discount,
            quantity,
            index: idx,
            bundle_name: bundleName,
            bundle_price: bundlePrice,
            barcode: li.variant?.barcode,
            item_brand: 'woom',
            item_category: cats[0],
            item_category2: cats[1],
            item_category3: cats[2],
            item_category4: cats[3],
            item_category5: cats[4],
            item_variant: li.variant?.shopTitle === 'Default Title' ? '' : li.variant?.shopTitle,
        };
    }

    viewCart(cart: CheckoutMutation_ManageCheckout_Checkout) {
        // console.log('view cart', cart);
        this.ecommerceClear();
        if (cart) {
            const currency = cart.totalPrice?.currencyCode || 'USD';
            const items = [];
            let idx = 0;
            for (const li of cart.lineItems) {
                // is this right? use 1 if quantity is missing?
                const quantity = li.quantity || 1;
                items.push(this.processCartItem(li, idx, quantity));
                idx += 1;
            }
            this.pushEvent({
                event: 'view_cart',
                ecommerce: {
                    currency,
                    value: this.numericPrice(cart.totalPrice?.amount || 0),
                    items,
                },
            });
        }
    }

    // removed because no longer used
    // ecommerceAdd(variant, name, type) {
    //     throw new Error('ecommerceAdd no longer used');
    //     /*
    //     // console.log('ecom gtm add')
    //     const price = get(variant, 'price.amount', '');
    //     const currency = get(variant, 'price.currencyCode', 'EUR');
    //     if (variant) {
    //         this.ecommerceClear();
    //         this.pushEvent({
    //             event: 'addToCart',
    //             ecommerce: {
    //                 currencyCode: currency,
    //                 add: {
    //                     // 'add' actionFieldObject measures.
    //                     products: [
    //                         {
    //                             name: name,
    //                             id: variant.sku,
    //                             price: price,
    //                             brand: 'woom',
    //                             category: type,
    //                             variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
    //                             quantity: 1
    //                         }
    //                     ]
    //                 }
    //             }
    //         });
    //         this.ecommerceClear();
    //         const cats = this.getCategories(type);
    //         this.pushEvent({
    //             event: 'add_to_cart',
    //             ecommerce: {
    //                 currency: currency,
    //                 value: this.numericPrice(price),
    //                 items: [
    //                     {
    //                         item_name: name,
    //                         item_id: variant.sku,
    //                         index: 0,
    //                         price: this.numericPrice(price),
    //                         barcode: variant.barcode,
    //                         item_brand: 'woom',
    //                         item_category: cats[0],
    //                         item_category2: cats[1],
    //                         item_category3: cats[2],
    //                         item_category4: cats[3],
    //                         item_category5: cats[4],
    //                         item_variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
    //                         quantity: 1
    //                     }
    //                 ]
    //             }
    //         });
    //     }
    //     */
    // }

    // we only deal in single quantities when items are added

    addCartItems(items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems) {
        // console.log('add cart items', items)
        if (Array.isArray(items) && items.length > 0) {
            let price = 0;
            const currency = items[0].variant?.price?.currencyCode || '';
            let idx = 0;
            const newItems = [];
            const oldItems = [];
            for (const i of items) {
                const data = this.processCartItem(i, idx, 1);
                const numericPrice = this.numericPrice(data.price);
                // console.log('item', data.item_name, data.price, data.discount)
                if (typeof numericPrice === 'number') {
                    price += numericPrice - data.discount;
                }
                newItems.push(data);
                oldItems.push({
                    name: data.item_name,
                    variant: data.item_variant,
                    id: data.item_id,
                    price: typeof numericPrice === 'number' ? `${numericPrice.toFixed(2)}` : data.price,
                    discount: `${data.discount.toFixed(2)}`,
                    brand: 'woom',
                    category: i.variant?.productType,
                    quantity: 1,
                });
                idx += 1;
            }
            // console.log('price is', price);
            this.ecommerceClear();
            this.pushEvent({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: currency,
                    add: {
                        // 'add' actionFieldObject measures.
                        products: oldItems,
                    },
                },
            });
            this.ecommerceClear();
            this.pushEvent({
                event: 'add_to_cart',
                ecommerce: {
                    currency,
                    value: price,
                    items: newItems,
                },
            });
            console.log('add cart items', price, newItems);
        }
    }

    // we only deal with single quantities when items are removed
    removeCartItems(items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems) {
        if (Array.isArray(items) && items.length > 0) {
            let price = 0;
            const currency = items[0].variant?.price?.currencyCode || '';
            let idx = 0;
            const newItems = [];
            const oldItems = [];
            for (const i of items) {
                const data = this.processCartItem(i, idx, 1);
                const numericPrice = this.numericPrice(data.price);
                // console.log('item', data.item_name, data.price, data.discount)
                if (typeof numericPrice === 'number') {
                    price += numericPrice - data.discount;
                }
                newItems.push(data);
                oldItems.push({
                    name: data.item_name,
                    variant: data.item_variant,
                    id: data.item_id,
                    price: typeof numericPrice === 'number' ? `${numericPrice.toFixed(2)}` : data.price,
                    discount: `${data.discount.toFixed(2)}`,
                    brand: 'woom',
                    category: i.variant?.productType,
                    quantity: 1,
                });
                idx += 1;
            }
            // console.log('price is', price);
            this.ecommerceClear();
            this.pushEvent({
                event: 'removeFromCart',
                ecommerce: {
                    currencyCode: currency,
                    remove: {
                        // 'add' actionFieldObject measures.
                        products: oldItems,
                    },
                },
            });
            this.ecommerceClear();
            this.pushEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    currency,
                    value: price,
                    items: newItems,
                },
            });
            console.log('remove cart items', price, newItems);
        }
    }

    // removed because no longer used
    // ecommerceRemove(variant: any, name: any, type: any) {
    //     throw new Error('ecommerceRemove no longer used');
    //     /*
    //     // console.log('ecom gtm remove')
    //     const price = get(variant, 'price.amount', '');
    //     const currency = get(variant, 'price.currencyCode', 'EUR');
    //     if (variant) {
    //         this.ecommerceClear();
    //         this.pushEvent({
    //             event: 'removeFromCart',
    //             ecommerce: {
    //                 currencyCode: currency,
    //                 remove: {
    //                     // 'add' actionFieldObject measures.
    //                     products: [
    //                         {
    //                             name: name,
    //                             id: variant.sku,
    //                             price: price,
    //                             brand: 'woom',
    //                             category: type,
    //                             variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
    //                             quantity: 1
    //                         }
    //                     ]
    //                 }
    //             }
    //         });
    //         this.ecommerceClear();
    //         const cats = this.getCategories(type);
    //         this.pushEvent({
    //             event: 'remove_from_cart',
    //             ecommerce: {
    //                 currency: currency,
    //                 value: this.numericPrice(price),
    //                 items: [
    //                     {
    //                         item_name: name,
    //                         item_id: variant.sku,
    //                         index: 0,
    //                         price: this.numericPrice(price),
    //                         barcode: variant.barcode,
    //                         item_brand: 'woom',
    //                         item_category: cats[0],
    //                         item_category2: cats[1],
    //                         item_category3: cats[2],
    //                         item_category4: cats[3],
    //                         item_category5: cats[4],
    //                         item_variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
    //                         quantity: 1
    //                     }
    //                 ]
    //             }
    //         });
    //     }
    //     */
    // }

    getWindowLayer() {
        // @ts-ignore
        return window[this.options.layer];
    }

    initPageTracking() {
        const router = useRouter();
        const route = useRoute();
        router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
            setTimeout(() => {
                // console.log('this', this)

                const controller = route.name || null;
                // console.log('router afterEach', language, region, to.gtm, to.fullPath, to.name, document.referrer) // eslint-disable-line no-console
                const referrer = document.referrer;
                let organic = false;
                if (referrer) {
                    const referrerHostname = referrer.split('/')[2];
                    const shortenedHostname = referrerHostname.substr(0, referrerHostname.lastIndexOf('.'));
                    const indexOfSearchEngines = searchEngines.indexOf(shortenedHostname);
                    if (indexOfSearchEngines > -1) {
                        organic = true;
                    }
                }
                // console.log('gtm pageview')
                this.getWindowLayer().push(
                    (to as any).gtm || {
                        event: 'nuxtRoute',
                        pageType: 'PageView',
                        pageUrl: to.fullPath,
                        routeName: to.name,
                        referrer,
                        bot: !organic,
                    },
                );
            }, 0);
        });
    }

    updateCart(obj_in: CheckoutMutation_ManageCheckout_Checkout_Extended | null, eventName: string | null | undefined = null) {
        try {
            if (!this.getWindowLayer()) {
                throw new Error('missing GTM dataLayer');
            }

            let obj: CheckoutMutation_ManageCheckout_Checkout_Extended | null = obj_in;

            const il = obj?.lineItems?.length || 0;
            // console.log('update cart obj', get(obj, 'lineItems.length', 0))
            if (!obj) {
                obj = {
                    cart: {
                        items: [],
                        basket_size: 0,
                        basket_value: 0,
                        currency: null,
                    },
                };
            } else {
                this.getWindowLayer().push({
                    event: 'cart-clear',
                    cart: null,
                });
                const items = [];
                let idx = 0;
                if (obj.lineItems) {
                    for (const li of obj.lineItems) {
                        items.push(this.processCartItem(li, idx, li.quantity || 1));
                        idx += 1;
                    }
                }
                obj = {
                    cart: {
                        items,
                        basket_size: obj.totalQuantity,
                        basket_value: obj.totalPrice ? this.numericPrice(obj.totalPrice.amount) : 0,
                        currency: obj.totalPrice ? obj.totalPrice.currencyCode : null,
                    },
                };
            }
            if (eventName) {
                obj.event = eventName;
            } else {
                obj.event = 'cart_update';
            }
            this.getWindowLayer().push(obj);
        } catch (err) {
            console.error('[ERROR] [GTM]', err);
        }
    }

    pushEvent(obj: any) {
        try {
            if (!this.getWindowLayer()) {
                throw new Error('missing GTM dataLayer');
            }
            if (typeof obj !== 'object') {
                throw new TypeError('event should be an object');
            }
            if (!obj.hasOwnProperty('ecommerce')) {
                if (!obj.hasOwnProperty('event')) {
                    throw new Error('missing event property');
                }
            }
            this.getWindowLayer().push(obj);
        } catch (err) {
            console.error('[ERROR] [GTM]', err);
        }
    }

    hasDNT() {
        return (
            (window as any).doNotTrack === '1' ||
            navigator.doNotTrack === 'yes' ||
            navigator.doNotTrack === '1' ||
            (navigator as any).msDoNotTrack === '1' ||
            (window.external && (window.external as any).msTrackingProtectionEnabled && (window.external as any).msTrackingProtectionEnabled())
        );
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const ctx: any = {};
    // const { route, store, redirect } = ctx

    const options: GTMModuleOptions = nuxtApp.$config.gtm;

    if (!options) {
        return;
    }

    // console.log('options', options)
    if (process.env.NODE_ENV !== 'production' && !options.dev) {
        return;
    }

    if (process.server) {
        return;
    }

    // console.log('inject gtm')
    const $gtm = new GTM(ctx, options);

    nuxtApp.provide('gtm', $gtm);

    $gtm.init();
    // get the current route
});
