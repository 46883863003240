export default {
    it: {
        'x-product-option-size': 'Seleziona la taglia',
        'x-product-description': 'Dettagli del prodotto',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'La vostra woom bike',
                label: 'Scegli la vostra woom bike',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'IVA inclusa, spese di spedizione escluse',
            color: 'Colore',
            fits: 'Adatto a',
            position: 'Posizione',
            edition: 'Edizione',
            language: 'Lingua',
            size: 'Taglia',
            nameField: 'Name',
            sizes: {
                guide: 'Informazioni sulle taglie',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom ORIGINAL 1+ (da nov. 2020), 2, 3',
                    m: 'ORIGINAL 4, 5 e OFF + OFF AIR 4',
                    l: 'ORIGINAL 6 e OFF + OFF AIR 5, 6 e UP 5, 6',
                },
            },
            variant: {
                front: 'davanti',
                rear: 'dietro',
                left: 'sinistro',
                right: 'destro',
                en: 'Inglese',
                de: 'Tedesca',
            },
            estimateDeliveryDate: 'Data di consegna prevista',
            // shippingPeriod: 'Articolo esaurito, ma disponibile per il preordine. Data di consegna prevista: {date}',
            quantity: 'Un articolo in magazzino. | {n} articoli in magazzino.',
            reviews: 'Nessuna recensione | Una recensione | {n} recensioni',
            estimatedShipping: 'Spese di spedizione previste',
            estimatedTaxes: 'Tasse previste',
            estimatedTotal: 'Totale previsto',
            availability: {
                not_for_sale: 'Non è possibile acquistare il prodotto in questa regione.',
                preorder: {
                    short: 'Articolo esaurito, ma disponibile per il preordine.',
                    long: 'Articolo esaurito, ma disponibile per il preordine.\nData di consegna prevista: {date}',
                    line: 'Data di consegna prevista: {date}',
                },

                comingsoon: {
                    short: 'Articolo esaurito.',
                    long: `Articolo esaurito.
                        Gli ordini dovrebbero essere di nuovo possibili tra {date}.`,
                },
                not_in_this_region: 'Il prodotto non è disponibile in questa regione.',
                notify_me: "Inserisci il tuo indirizzo e-mail e sarai il primo a sapere quando l'articolo sarà nuovamente disponibile.",
                out_of_stock: 'Esaurito',
                weeks: '{period} settimane',
            },
            detail: {
                bike: 'Dimensioni',
                weight: 'Peso',
                age: 'Età',
                height: 'Altezza',
                features: 'Particolarità',
                years: 'anni',
            },
            geometry: {
                height: 'Altezza',
                label: 'Dati geometrici',
                wheel: {
                    size: 'Dimensioni gomme/ruote',
                    base: 'Interasse',
                },
                handlebar: {
                    height: 'Altezza del cockpit/manubrio',
                    adjust: 'Regolazione del manubrio',
                },
                steering: {
                    angle: 'Angolo di sterzo',
                },
                top_tube: {
                    horizontal: 'Lunghezza tubo superiore',
                },
                seat_tube: {
                    angle: 'Angolo tubo sella',
                },
                saddle: {
                    height: {
                        min: 'Altezza minima della sella',
                        max: 'Altezza massima della sella',
                    },
                },
                crank: {
                    length: 'Lunghezza pedivella',
                },
                standover: {
                    height: 'Standover',
                },
            },
            specs: {
                label: 'Specifiche',
            },
            configurator: {
                added: 'Check! La {product} ha pedalato fino al tuo carrello.',
                cart: 'vai al carrello',
                extras: 'Forse ti interessa anche',
            },
            matchingProducts: 'Altri articoli adatti',
        },
        free: 'Gratuito',
        tip: 'Consiglio',
        cart: {
            add: 'Aggiungi al carrello',
            add_preorder: 'Aggiungi al carrello',
            add_short: 'Aggiungere',
            options: 'Opzioni',
            yourCart: 'Il tuo carrello',
            viewCart: "Visualizza il carrello e completa l'ordine",
            tell_me_first: 'Voglio essere il primo a saperlo!',
            checkout: 'Cassa',
            reviewCart: 'Verifica il carrello',
            empty: 'Il tuo carrello è vuoto.\nTrova la bicicletta giusta per tuo figlio!',
            total: 'Totale',
            subtotal: 'Subtotale',
            items: 'Vuoto | 1 articolo | {n} articoli',
            shipping: 'Spedizione',
            additionalShippingCharges: 'Per articoli particolarmente grandi ci potrebbero essere spese di spedizione extra al momento del check-out.',
            needHelp: 'Hai bisogno di aiuto?',
            productUnavailable: 'Siamo spiacenti, questo prodotto al momento non è disponibile.',
            maxQuantityReached: 'Spiacenti, la quantità massima per questo prodotto è {quantity}.',
            limitedQuantityAvailable: 'Ne hai messi {currentQuantity} nel carrello, ma attualmente ne sono disponibili solo {quantityAvailable}.',
            couldNotAdd: 'Impossibile aggiungere il prodotto al carrello.',
            limit: 'Numero massimo raggiunto',
        },
        forms: {
            validation: {
                required: 'Il campo non può essere vuoto.',
                email: 'Il campo non può essere vuoto.',
                right: 'The following fields contain errors: {fields}',
                age: 'Please provide us with a valid age.',
                number: 'A number is required in this field.',
                select: 'Hai dimenticato di selezionare.',
                file: 'Please select a file for upload.',
                consent: 'We would need your consent to proceed.',
            },
        },
        landing: {
            selector: {
                country: 'Paese',
                language: 'Lingua',
                button: 'Apri il sito web',
            },
        },
        bike: {
            bike: 'Bicicletta',
            years: 'anni',
            child: 'Bambino',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Scopri le woom bike ORIGINAL',
                off_off_air: 'Scopri le mountain bike',
                up: 'Scopri le e-mountain bike',
                now: 'Scopri le woom NOW',
            },
        },
        bikefinder: {
            link: 'Trova la bici adatta.',
            years: 'anni',
            how_it_works: 'Come funziona?',
            next: 'Avanti',
            back: 'Indietro',
            see_results: 'Visualizza i risultati',
            back_to_start: 'Comincia da capo',
            share: 'Condividi i risultati',
            height: {
                small: 'piccolo',
                medium: 'nella media',
                large: 'alto',
            },
            skill: {
                beginner: 'principiante',
                advanced: 'esperto',
            },
            gender: {
                boy: 'boy',
                girl: 'girl',
            },
        },
        article: {
            readmore: 'Per saperne di più',
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Iscriviti alla newsletter',
            },
            copyright: 'Copyright © 2021 woom GmbH. Tutti i diritti riservati.',
            contact: 'Contattaci',
        },
        error: {
            homepage: 'Torna alla home',
            404: `Ci spiace, ma non troviamo la pagina richiesta.
    È possibile che abbiate seguito un link obsoleto oppure che la pagina sia stata rimossa.`,
            '5xx': `Uuuuups!
    There is something wrong with our servers.
    We will be back soon.`,
        },
        campaign: {
            gdg: {
                headline: `Pedalare, mangiare il gelato,
            accumulare chilometri e fare del bene!`,
                headlineMillion: 'Pedalare, mangiare il gelato e vincere fantastici premi!',
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Partecipa ora!',
                woomSupportsUnicef: 'al fianco di',
                unicefInfo: "L'UNICEF non sostiene aziende, marchi, prodotti o servizi.",
            },
            nowLaunch: {
                title: 'Preparati.\nPer una bicicletta che cambierà tutto.',
                ctaLabel: 'Per saperne di più',
                ctaLink: 'Iscriviti-alla-newsletter',
                countdown: {
                    days: {
                        singular: 'Giorni',
                        plural: 'Giorni',
                    },
                    hours: {
                        singular: 'Ore',
                        plural: 'Ore',
                    },
                    minutes: {
                        singular: 'Minuti',
                        plural: 'Minuti',
                    },
                    seconds: {
                        singular: 'Secondi',
                        plural: 'Secondi',
                    },
                },
            },
        },
    },
};
