export default {
    sv: {
        'x-product-option-size': 'Välj storlek',
        'x-product-description': 'Produktuppgifter',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Din woom-cykel',
                label: 'Välj din woom-cykel',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'inkl. moms, exkl. leverans',
            plusCustomFees: 'Plus customs fees where applicable*',
            plusCustomFeesLink: 'https://help.woombikes.com/article/589-are-customs-fees-charged-for-orders-placed-outside-of-the-eu',
            color: 'Färg',
            fits: 'passar till',
            position: 'Position',
            edition: 'Modell',
            language: 'Språk',
            size: 'Storlek',
            nameField: 'Name',
            sizes: {
                guide: 'Storleksinformation',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                Cykelstöd: {
                    s: 'woom ORIGINAL 1 PLUS (från november 2020), woom ORIGINAL 2 & 3',
                    m: 'woom ORIGINAL 4 & 5, woom OFF/OFF AIR 4',
                    l: 'woom ORIGINAL 6, woom OFF/OFF AIR 5 & 6, woom UP 5 & 6',
                },
            },
            variant: {
                front: 'framtill',
                rear: 'baktill',
                left: 'vänster',
                right: 'höger',
                en: 'Engelska',
                de: 'Tyska',
            },
            estimateDeliveryDate: 'beräknat leveransdatum',
            // shippingPeriod: 'Finns för närvarande inte i lager men kan förbeställas. Beräknat leveransdatum: <b>{date}</b>.',
            quantity: 'En artikel på lager | {n} artiklar på lager',
            reviews: 'Inga recensioner | En recension | {n} recensioner',
            estimatedShipping: 'beräknad leveranskostnad',
            estimatedTaxes: 'beräknad skatt',
            estimatedTotal: 'beräknat totalbelopp',
            availability: {
                not_for_sale: 'Denna produkt säljs inte i denna region.',
                preorder: {
                    short: 'Finns för närvarande inte i lager men kan förbeställas.',
                    long: 'Finns för närvarande inte i lager men kan förbeställas.\nBeräknat leveransdatum: <b>{date}</b>',
                    line: 'Expected to ship by {date}',
                },

                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
              ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'Denna produkt finns är inte tillgänglig i denna region.',
                notify_me: 'Ange din e-postadress och bli bland de första som får veta när artikeln finns i lager igen.',
                out_of_stock: 'Slutsåld',
                weeks: '{period} veckor',
            },
            detail: {
                bike: 'Cykelstorlek',
                weight: 'Vikt',
                age: 'Ålder',
                height: 'Storlek',
                features: 'Särskilda egenskaper',
                years: 'År',
            },
            geometry: {
                height: 'Storlek',
                label: 'Konstruktion',
                wheel: {
                    size: 'Däck-/hjulstorlek',
                    base: 'Hjulbas',
                },
                handlebar: {
                    height: 'Cockpit-/grepphöjd',
                    adjust: 'Inställning av styret',
                },
                steering: {
                    angle: 'Styrvinkel',
                },
                top_tube: {
                    horizontal: 'Horisontal överrörslängd',
                },
                seat_tube: {
                    angle: 'Sadelrörsvinkel',
                },
                saddle: {
                    height: {
                        min: 'Lägsta sadelhöjd',
                        max: 'Maximal sadelhöjd',
                    },
                },
                crank: {
                    length: 'Vevarmens längd',
                },
                standover: {
                    height: 'Standover-höjd',
                },
            },
            specs: {
                label: 'Specifikationer',
            },
            configurator: {
                added: 'Klart! Din {product} ligger nu i din varukorg.',
                cart: 'fortsätt till varukorgen',
                extras: 'Du kanske även är intresserad av dessa produkter:',
            },
            matchingProducts: 'Dessa produkter passar till:',
        },
        free: 'gratis',
        tip: 'Tips!',
        cart: {
            add: 'lägg i varukorg',
            add_short: 'lägg till',
            add_preorder: 'Add to cart (Pre-order)',
            options: 'Tillval',
            yourCart: 'Din varukorg',
            viewCart: 'Visa varukorg & slutför köp',
            tell_me_first: 'Informera mig först!',
            checkout: 'Kassa',
            reviewCart: 'Kontrollera varukorg',
            empty: 'Din varukorg är tom.\nHitta rätt cykel till ditt barn!',
            total: 'Totalbelopp',
            subtotal: 'Delsumma',
            items: 'tom | 1 artikel | {n} artiklar',
            shipping: 'Leverans',
            additionalShippingCharges: 'För skrymmande artiklar kan ytterligare leveranskostnader tillkomma i kassan.',
            needHelp: 'Frågor?',
            productUnavailable: 'Tyvärr finns denna produkt inte tillgänglig just nu.',
            maxQuantityReached: 'Tyvärr finns endast {quantity} st. av denna produkt på lager.',
            limitedQuantityAvailable: 'Du har lagt {currentQuantity} i din varukorg men för närvarande har vi bara {quantityAvailable} st. på lager.',
            couldNotAdd: 'Produkten kunde inte läggas till i varukorgen.',
            limit: 'Ordergräns överskriden',
        },
        forms: {
            validation: {
                required: 'Detta fält måste fyllas i.',
                email: 'Det krävs en giltig e-postadress.',
                right: 'Följande fält innehåller fel: {fields}',
                age: 'Ange en giltig ålder.',
                number: 'Ange ett tal.',
                select: 'Välj ett urval.',
                file: 'Välj en fil för uppladdning.',
                consent: 'Vi behöver ditt samtycke.',
            },
        },
        landing: {
            selector: {
                country: 'Land',
                language: 'Språk',
                button: 'Gå till webbplatsen',
            },
        },
        bike: {
            bike: 'Bike',
            years: 'years',
            child: 'Child',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Discover the woom ORIGINAL bikes',
                off_off_air: 'Discover mountain bikes',
                up: 'Discover e-mountain bikes',
                now: 'Discover the woom NOW',
            },
        },
        bikefinder: {
            link: 'Hitta rätt cykel!',
            years: 'Ålder',
            how_it_works: 'Hur fungerar det?',
            next: 'fortsätt',
            back: 'tillbaka',
            see_results: 'visa resultat',
            back_to_start: 'börja från början',
            share: 'dela resultat',
            height: {
                small: 'kort',
                medium: 'genomsnittlig',
                large: 'lång',
            },
            skill: {
                beginner: 'nybörjare',
                advanced: 'avancerad',
            },
            gender: {
                boy: 'pojke',
                girl: 'flicka',
            },
        },
        article: {
            readmore: 'läs mer',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
            count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
            and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
            nowLaunch: {
                title: 'Get ready.\nTo meet the bike that changes everything.',
                ctaLabel: 'Learn more',
                ctaLink: 'newslettersubscription',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Days',
                    },
                    hours: {
                        singular: 'Hour',
                        plural: 'Hours',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Second',
                        plural: 'Seconds',
                    },
                },
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Prenumerera på nyhetsbrevet',
            },
            copyright: 'Copyright © 2021 woom GmbH. Med ensamrätt.',
            contact: 'Kontakt',
        },
        error: {
            homepage: 'Gå till startsida',
            404: `Tyvärr gick sidan du sökte inte att hitta.
          Det kan bero på att länken inte var giltig eller att sidan har tagits bort.`,
            '5xx': `Hoppsan! Just nu pågår det underhållsarbete på servern. Men vi är snart tillbaka!`,
        },
    },
};
