import TypedEmitter from 'typed-emitter'; //, { EventMap }
import { ComponentType } from '~/common/types';
import { PageByUrlQuery_PageByUrl_Page_Product, PageByUrlQuery_PageByUrl_Page_Product_Category } from '~/common/types/nestedGraphqlTypes';
import { UnitsType } from '~/store/app';

// TODO
export type GlobalDrawerEventParams = {
    label: string; // this.$trans('cart.tell_me_first'),
    component: ComponentType;
    content: {
        variant?: any; // item,
        data?: any; // this.upsellData,
        category?: PageByUrlQuery_PageByUrl_Page_Product_Category; // this.page.product.category,
        product?: PageByUrlQuery_PageByUrl_Page_Product;
        pageId?: string | null;
        refLookup?: Function; // this.refLookup,
        geometry?: any;
        units?: UnitsType;
    };
    pageId?: string | null; // this.pageId,
    product?: PageByUrlQuery_PageByUrl_Page_Product; // null,
    refLookup?: Function; // this.refLookup,
    // gtmPayload: any; // gtmPayload,
};

// TODO
type FormEventParams = {
    // ...this.notifyMeForm,
    label: string; // this.$trans('cart.tell_me_first'),
    context: {
        pageUri: string; // `woom.com/notify-me/${this.productId.replace(/[:]/ig, '/')}/${this.variantSlug}`,
        pageName: string;
        // set the backInStock flag if this page is one of: [pl_PL]
        backInStock: boolean; // this.isBackInStock,
    };
    gtmPayload: any; // gtmPayload,
};

// TODO
type VideoEventParams = {
    functionalConsent: boolean;
};

// TODO
type OpenImageFullScreenEventParams = {
    imageIndex: number;
    images: {
        container: any; // TODO - should be dom element ref
        observer: any; // null
        initialObservationDone: boolean;
        galleryWidth: number;
        windowWidth: number;
        current: number;
        showIndex: boolean;
    };
};

// UNKNOWN_TYPE
type UserEntryEventParams = {
    item: any; // this.$route.query.entry,
    share: any;
};

export type OpenCartEventParams = { variant: unknown; product: any }; // UNKNOWN_TYPE

type WoomEventEmitterEvents = {
    'clear-form': () => void;
    form: (data: FormEventParams) => void; // TODO
    'global-drawer': (data: GlobalDrawerEventParams) => void;
    'language-switch': () => void;
    'open-cart': (data: OpenCartEventParams) => void; // TODO
    'open-image-fullscreen': (data: OpenImageFullScreenEventParams) => void;
    'open-geometry': () => void;
    'open-specs': () => void;
    'open-video': (data: VideoEventParams) => void;
    'user-entry': (data: UserEntryEventParams) => void;
};

export class WoomEventEmitter implements TypedEmitter<WoomEventEmitterEvents> {
    constructor() {}

    addListener<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.addListener(event, listener);
        return this;
    }

    on<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.on(event, listener);
        return this;
    }

    once<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.once(event, listener);
        return this;
    }

    prependListener<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.prependListener(event, listener);
        return this;
    }

    prependOnceListener<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.prependOnceListener(event, listener);
        return this;
    }

    off<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.off(event, listener);
        return this;
    }

    removeAllListeners<E extends keyof WoomEventEmitterEvents>(event?: E | undefined): this {
        this.removeAllListeners(event);
        return this;
    }

    removeListener<E extends keyof WoomEventEmitterEvents>(event: E, listener: WoomEventEmitterEvents[E]): this {
        this.removeListener(event, listener);
        return this;
    }

    emit<E extends keyof WoomEventEmitterEvents>(event: E, ...args: Parameters<WoomEventEmitterEvents[E]>): boolean {
        return this.emit(event, ...args);
    }

    eventNames(): (string | symbol)[] {
        return this.eventNames();
    }

    rawListeners<E extends keyof WoomEventEmitterEvents>(event: E): WoomEventEmitterEvents[E][] {
        return this.rawListeners(event);
    }

    listeners<E extends keyof WoomEventEmitterEvents>(event: E): WoomEventEmitterEvents[E][] {
        return this.listeners(event);
    }

    listenerCount<E extends keyof WoomEventEmitterEvents>(event: E): number {
        return this.listenerCount(event);
    }

    getMaxListeners(): number {
        return this.getMaxListeners();
    }

    setMaxListeners(maxListeners: number): this {
        this.setMaxListeners(maxListeners);
        return this;
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const emitter = new WoomEventEmitter();

    nuxtApp.provide('eventEmitter', emitter);
});
