export default {
    de: {
        'x-product-option-size': 'Größe auswählen',
        'x-product-description': 'Produktdetails',
        google: {
            form: {
                addFile: 'Datei auswählen',
                clearFiles: 'entfernen',
                noFiles: 'Keine Datei ausgewählt',
                submit: 'Abschicken',
                previous: 'Zurück',
                next: 'Weiter',
            },
        },
        selector: {
            original: {
                key: 'Euer woom bike',
                label: 'Euer woom bike auswählen',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            color: 'Farbe',
            fits: 'Passend für',
            position: 'Position',
            edition: 'Edition',
            language: 'Sprache',
            size: 'Größe',
            tax: 'Preis inkl. MWSt. zzgl. Versandkosten',
            plusCustomFees: 'ggf. zzgl. Zollgebühren*',
            plusCustomFeesLink: 'https://hilfe.woombikes.com/article/590-fallen-fur-bestellungen-auserhalb-der-eu-zollgebuhren-an',
            nameField: 'Name',
            sizes: {
                guide: 'Größeninfo',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom ORIGINAL 1+ (ab Nov.2020), 2, 3',
                    m: 'ORIGINAL 4, 5 und OFF + OFF AIR 4',
                    l: 'ORIGINAL 6 und OFF + OFF AIR 5, 6 und UP 5, 6',
                },
            },
            variant: {
                front: 'vorne',
                rear: 'hinten',
                left: 'links',
                right: 'rechts',
                en: 'Englisch',
                de: 'Deutsch',
            },
            estimateDeliveryDate: 'voraussichtliches Lieferdatum',
            // shippingPeriod: 'Derzeit nicht vorrätig, aber vorbestellbar. Voraussichtliches Lieferdatum: {date}',
            quantity: 'Ein Artikel auf Lager.  | {n} Artikel auf Lager.',
            reviews: 'Keine Bewertungen | Eine Bewertung | {n} Bewertungen',
            estimatedShipping: 'voraussichtliche Versandkosten',
            estimatedTaxes: 'voraussichtliche Steuern',
            estimatedTotal: 'voraussichtliche Gesamtsumme',
            availability: {
                not_for_sale: 'Dieses Produkt wird in dieser Region nicht zum Kauf angeboten.',
                preorder: {
                    short: 'Derzeit nicht vorrätig, aber vorbestellbar.',
                    long: 'Derzeit nicht vorrätig, aber vorbestellbar.\nVoraussichtliches Lieferdatum: {date}',
                    line: 'Voraussichtliches Lieferdatum: {date}',
                },
                comingsoon: {
                    short: 'Derzeit nicht vorrätig.',
                    long: 'Derzeit nicht vorrätig.\nVoraussichtlich wieder bestellbar in {date}.',
                },
                not_in_this_region: 'Dieses Produkt ist in dieser Region nicht verfügbar.',
                notify_me: 'E-Mail-Adresse eingeben und als Erster erfahren, wann dieser Artikel wieder lieferbar ist.',
                out_of_stock: 'Ausverkauft',
                weeks: '{period} Wochen',
            },
            detail: {
                bike: 'Größe',
                weight: 'Gewicht',
                age: 'Alter',
                height: 'Größe',
                features: 'Besonderheiten',
                years: 'Jahre',
            },
            geometry: {
                height: 'Größe',
                label: 'Geometriedaten',
                wheel: {
                    size: 'Reifen-/Laufradgröße',
                    base: 'Radstand',
                },
                handlebar: {
                    height: 'Cockpit-/Griffhöhe',
                    adjust: 'Lenkerverstellung',
                },
                steering: {
                    angle: 'Lenkwinkel',
                },
                top_tube: {
                    horizontal: 'horizontale Oberrohrlänge',
                },
                seat_tube: {
                    angle: 'Sitzrohrwinkel',
                },
                saddle: {
                    height: {
                        min: 'niedrigste Sattelhöhe',
                        max: 'maximale Sattelhöhe',
                    },
                },
                crank: {
                    length: 'Kurbelarmlänge',
                },
                standover: {
                    height: 'Überstandshöhe',
                },
            },
            specs: {
                label: 'Spezifikationen',
            },
            configurator: {
                added: 'Check! Das {product} ist erfolgreich in deinen Warenkorb geradelt.',
                cart: 'Weiter zum Warenkorb',
                extras: 'Diese Produkte könnten dich auch interessieren',
            },
            matchingProducts: 'Diese Produkte passen dazu',
        },
        free: 'kostenlos',
        tip: 'Tipp',
        cart: {
            add: 'Zum Warenkorb hinzufügen',
            add_preorder: 'Zum Warenkorb hinzufügen',
            add_short: 'Hinzufügen',
            options: 'Optionen',
            yourCart: 'Dein Warenkorb',
            viewCart: 'Warenkorb ansehen & Einkauf abschließen',
            tell_me_first: "Sagt's mir zuerst!",
            checkout: 'Check-out',
            reviewCart: 'Warenkorb überprüfen',
            empty: 'Dein Warenkorb ist leer. \nFinde jetzt das passende Fahrrad für dein Kind!',
            total: 'Gesamtsumme',
            subtotal: 'Zwischensumme',
            items: 'leer | 1 Artikel | {n} Artikel',
            shipping: 'Versand',
            additionalShippingCharges: 'Für übergroße Artikel können beim Check-out weitere Versandkosten hinzukommen.',
            needHelp: 'Fragen?',
            productUnavailable: 'Es tut uns leid, dieses Produkt ist momentan nicht verfügbar.',
            maxQuantityReached: 'Es tut uns leid, die maximal verfügbare Anzahl für dieses Produkt ist {quantity}.',
            limitedQuantityAvailable: 'Du hast {currentQuantity} in deinen Warenkorb gelegt, momentan sind aber nur {quantityAvailable} verfügbar.',
            couldNotAdd: 'Das Produkt konnte dem Warenkorb nicht hinzugefügt werden.',
            limit: 'Bestelllimit erreicht',
        },
        forms: {
            validation: {
                required: 'Dieses Feld darf nicht leer sein.',
                email: 'Bitte gib eine gültige E-Mail-Adresse ein.',
                right: 'In den folgenden Feldern sind Fehler aufgetreten: {fields}',
                age: 'Bitte gib dein Alter an.',
                number: 'Bitte gib eine Zahl ein.',
                select: 'Bitte triff eine Auswahl.',
                file: 'Bitte lade ein Bild bzw. eine Datei hoch.',
                consent: 'Wir brauchen Deine Zustimmung.',
            },
        },
        landing: {
            selector: {
                country: 'Land',
                language: 'Sprache',
                button: 'Website aufrufen',
            },
        },
        bike: {
            bike: 'Rad',
            years: 'Jahre',
            child: 'Kind',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Entdecke die woom ORIGINAL bikes',
                off_off_air: 'Mountainbikes entdecken',
                up: 'E-Mountainbikes entdecken',
                now: 'woom NOW entdecken',
            },
        },
        bikefinder: {
            link: 'Finde das passende Rad.',
            years: 'Jahre',
            how_it_works: "Wie funktioniert's?",
            next: 'weiter',
            back: 'zurück',
            see_results: 'Ergebnisse anzeigen',
            back_to_start: 'zurück zum Anfang',
            share: 'Ergebnisse teilen',
            height: {
                small: 'klein',
                medium: 'durchschnittlich',
                large: 'groß',
            },
            skill: {
                beginner: 'Anfänger',
                advanced: 'fortgeschritten',
            },
            gender: {
                boy: 'Bub',
                girl: 'Mädchen',
            },
        },
        article: {
            readmore: 'mehr erfahren',
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Newsletter abonnieren',
            },
            copyright: 'Copyright © 2020 woom GmbH. Alle Rechte vorbehalten.',
            contact: 'Kontakt',
        },
        error: {
            homepage: 'Zur Startseite',
            404: `Wir können die aufgerufene Seite leider nicht finden.
    Vielleicht ist der Link ungültig oder die Seite ist umgezogen.`,
            '5xx': `Uuuuups!
    There is something wrong with our servers.
    We will be back soon.`,
        },
        campaign: {
            nameOrTitle: 'Name oder Titel',
            age: 'Alter',
            ageoption: '{age} Jahre',
            search: 'Einträge Filtern',
            noresults: 'Keine Einträge gefunden',
            loadmore: 'Mehr laden',
            btn: {
                search: 'Suche',
                clear: 'Zurücksetzen',
            },
            gdg: {
                headline: `Radeln, Eis essen,
            Kilometer sammeln & Gutes tun!`,
                headlineMillion: 'Radeln, Eis essen & tolle Preise gewinnen!',
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Jetzt mitmachen!',
                woomSupportsUnicef: 'unterstützt',
                unicefInfo: 'UNICEF unterstützt keine Unternehmen, Marken, Produkte oder Dienstleistungen.',
            },
            nowLaunch: {
                title: 'Mach dich bereit.\nFür ein Bike, das alles verändern wird.',
                ctaLabel: 'Mehr erfahren',
                ctaLink: 'newsletteranmeldung',
                countdown: {
                    days: {
                        singular: 'Tag',
                        plural: 'Tage',
                    },
                    hours: {
                        singular: 'Stunde',
                        plural: 'Stunden',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minuten',
                    },
                    seconds: {
                        singular: 'Sekunde',
                        plural: 'Sekunden',
                    },
                },
            },
        },
    },
    'de-at': {
        product: {
            tax: 'Preis inkl. USt. zzgl. Versandkosten',
        },
    },
    'de-ch': {
        product: {
            configurator: {
                extras: 'Extras für dein velo',
            },
        },
    },
};
