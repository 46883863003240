import revive_payload_client_bhHHEEJQHh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_eslint@8.46.0_sass@1.63.6_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_8qbPSpUBMX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_eslint@8.46.0_sass@1.63.6_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_7zNmuBaWmG from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import unhead_FGt3SFkDek from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_eslint@8.46.0_sass@1.63.6_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_Yx8rjHwrvy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_eslint@8.46.0_sass@1.63.6_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_7ujUXaJSh8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_vue-router@4.2.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_iRi1fAt7ug from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_vue-router@4.2.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kzDUalgQEt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_eslint@8.46.0_sass@1.63.6_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_client_client_client_l5lhlA5afs from "/opt/buildhome/repo/plugins/apollo-client-client.client.ts";
import appState_6zDlVSUkMw from "/opt/buildhome/repo/plugins/appState.ts";
import event_emitter_3veoQFF54T from "/opt/buildhome/repo/plugins/event-emitter.ts";
import gtm_cXsUZobmSF from "/opt/buildhome/repo/plugins/gtm.ts";
import i18n_VfGcjrvSkj from "/opt/buildhome/repo/plugins/i18n.ts";
import request_context_sSZWmJ36U3 from "/opt/buildhome/repo/plugins/request-context.ts";
import scroll_S7pZiC3d46 from "/opt/buildhome/repo/plugins/scroll.ts";
export default [
  revive_payload_client_bhHHEEJQHh,
  router_8qbPSpUBMX,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_FGt3SFkDek,
  prefetch_client_Yx8rjHwrvy,
  composition_7ujUXaJSh8,
  i18n_iRi1fAt7ug,
  chunk_reload_client_kzDUalgQEt,
  apollo_client_client_client_l5lhlA5afs,
  appState_6zDlVSUkMw,
  event_emitter_3veoQFF54T,
  gtm_cXsUZobmSF,
  i18n_VfGcjrvSkj,
  request_context_sSZWmJ36U3,
  scroll_S7pZiC3d46
]