export default {
    da: {
        'x-product-option-size': 'Vælg størrelse',
        'x-product-description': 'Produktinformationer',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Jeres woom bike',
                label: 'Vælg jeres woom bike',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'inkl. moms plus forsendelseg',
            plusCustomFees: 'Plus customs fees where applicable*',
            plusCustomFeesLink: 'https://help.woombikes.com/article/589-are-customs-fees-charged-for-orders-placed-outside-of-the-eu',
            color: 'Farve',
            fits: 'passer til',
            position: 'Position',
            edition: 'Model',
            language: 'Sprog',
            size: 'Størrelse',
            nameField: 'Name',
            sizes: {
                guide: 'Information om størrelse',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                Støtteben: {
                    s: 'woom ORIGINAL 1 PLUS (fra nov. 2020), woom ORIGINAL 2 & 3',
                    m: 'woom ORIGINAL 4 & 5, woom OFF/OFF AIR 4',
                    l: 'woom ORIGINAL 6, woom OFF/OFF AIR 5 & 6, woom UP 5 & 6',
                },
            },
            variant: {
                front: 'foran',
                rear: 'bagtil',
                left: 'til venstre',
                right: 'til højre',
                en: 'Engelsk',
                de: 'Tysk',
            },
            estimateDeliveryDate: 'Forventet leveringstid',
            // shippingPeriod: 'Available for pre-order and estimated to ship by <b>{date}</b>.',
            quantity: 'En vare er på lager | {n} varer på lager',
            reviews: 'Ingen anmeldelser | En anmeldelse | {n} anmeldelser',
            estimatedShipping: 'Forventede forsendelsesudgifter',
            estimatedTaxes: 'Forventede afgifter',
            estimatedTotal: 'Forventet samlet beløb',
            availability: {
                not_for_sale: 'Dette produkt sælges ikke i denne region.',
                preorder: {
                    short: 'Er p.t. ikke på lager, men kan reserveres.',
                    long: 'Er p.t. ikke på lager, men kan reserveres.\nForventet leveringstid: <b>{date}</b>',
                    line: 'Forventet leveringstid: {date}',
                },

                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
          ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'Dette produkt er ikke til rådighed i denne region.',
                notify_me: 'Indtast din e-mailadresse og få som den første besked, når varen igen kan leveres.',
                out_of_stock: 'Udsolgt',
                weeks: '{period} uger',
            },
            detail: {
                bike: 'Cykelstørrelse',
                weight: 'Vægt',
                age: 'Alder',
                height: 'Størrelse',
                features: 'Egenskaber',
                years: 'År',
            },
            geometry: {
                height: 'Størrelse',
                label: 'Geometriske data',
                wheel: {
                    size: 'Dæk-/hjulstørrelse',
                    base: 'Hjulafstand',
                },
                handlebar: {
                    height: 'Cockpit-/håndtagshøjde',
                    adjust: 'Styrjustering',
                },
                steering: {
                    angle: 'Styrvinkel',
                },
                top_tube: {
                    horizontal: 'Horisontal overrørslængde',
                },
                seat_tube: {
                    angle: 'Sadelpindens vinkel',
                },
                saddle: {
                    height: {
                        min: 'Laveste sadelhøjde',
                        max: 'Maksimal sadelhøjde',
                    },
                },
                crank: {
                    length: 'Pedalarmenes længde',
                },
                standover: {
                    height: 'Overstandshøjde',
                },
            },
            specs: {
                label: 'Specifikationer',
            },
            configurator: {
                added: 'Tjek! {product} er kørt ind i din indkøbskurv.',
                cart: 'videre til indkøbskurven',
                extras: 'Disse produkter er måske også noget for dig:',
            },
            matchingProducts: 'Her er nogle produkter, der passer til:',
        },
        free: 'gratis',
        tip: 'Tip',
        cart: {
            add: 'Læg i indkøbskurven',
            add_short: 'tilføj',
            add_preorder: 'Add to cart (Pre-order)',
            options: 'Muligheder',
            yourCart: 'Din indkøbskurv',
            viewCart: 'Se indkøbskurven og afslut dit indkøb',
            tell_me_first: 'Giv først mig besked!',
            checkout: 'Check-out',
            reviewCart: 'Kontrollér indkøbskurv',
            empty: 'Din indkøbskurv er tom.\nFind her en passende cykel til dit barn!',
            total: 'Samlet beløb',
            subtotal: 'Mellemsum',
            items: 'tom | 1 vare | {n} varer',
            shipping: 'Forsendelse',
            additionalShippingCharges: 'Der kan påløbe sig ekstra forsendelsesudgifter for varer af stor størrelse ved check-out.',
            needHelp: 'Spørgsmål?',
            productUnavailable: 'Vi beklager, men dette produkt har vi p.t. ikke på lager.',
            maxQuantityReached: 'Vi beklager, men det maksimalt mulige antal for dette produkt er {quantity}.',
            limitedQuantityAvailable: 'Du har lagt {currentQuantity} i din indkøbskurv. P.t. er kun {quantityAvailable} på lager.',
            couldNotAdd: 'Produktet kunne ikke lægges i din indkøbskurv.',
            limit: 'Ordregrænse nået',
        },
        forms: {
            validation: {
                required: 'Dette felt må ikke være tomt.',
                email: 'Du skal indtaste en gyldig e-mailadresse.',
                right: 'Følgende felter indeholder fejl: {fields}',
                age: 'Indtast venligst en gyldig alder.',
                number: 'Indtast venligst et tal.',
                select: 'ælg venligst en option.',
                file: 'Vælg venligst.',
                consent: 'Vi har brug for dit samtykke.',
            },
        },
        landing: {
            selector: {
                country: 'Land',
                language: 'Sprog',
                button: 'Åbn websitet',
            },
        },
        bike: {
            bike: 'Bike',
            years: 'years',
            child: 'Child',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Discover the woom ORIGINAL bikes',
                off_off_air: 'Discover mountain bikes',
                up: 'Discover e-mountain bikes',
                now: 'Discover the woom NOW',
            },
        },
        bikefinder: {
            link: 'Find den perfekte cykel.',
            years: 'Alder',
            how_it_works: 'Hvordan fungerer det?',
            next: 'næste',
            back: 'tilbage',
            see_results: 'vis resultater',
            back_to_start: 'start forfra',
            share: 'del resultater',
            height: {
                small: 'lille',
                medium: 'gennemsnitlig',
                large: 'stor',
            },
            skill: {
                beginner: 'Begynder',
                advanced: 'Erfaren',
            },
            gender: {
                boy: 'Dreng',
                girl: 'Pige',
            },
        },
        article: {
            readmore: 'Læs mere',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
        count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
        and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
            nowLaunch: {
                title: 'Get ready.\nTo meet the bike that changes everything.',
                ctaLabel: 'Learn more',
                ctaLink: 'newslettersubscription',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Days',
                    },
                    hours: {
                        singular: 'Hour',
                        plural: 'Hours',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Second',
                        plural: 'Seconds',
                    },
                },
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Abonnér nyhedsbrevet',
            },
            copyright: 'Copyright © 2021 woom GmbH. Alle rettigheder forbeholdt.',
            contact: 'Kontakt',
        },
        error: {
            homepage: 'Åbn startsiden',
            404: `Vi beklager, men vi kan ikke finde den ønskede side.
      Du har muligvis åbnet et ugyldigt link eller siden er blevet fjernet.`,
            '5xx': `Uuups! Der er p.t. arbejder i gang på serveren. Vi er har snart igen!`,
        },
    },
};
