export type ScrollDir = 'up' | 'down';

export class HBScroller {
    menuHeight: number;
    isPortraitPhone: boolean;
    scroll: number;
    scrollDir: ScrollDir;
    viewport: {
        width: number;
        height: number;
        scale: number;
        top: 0;
        vw: number;
        vh: number;
        aspect: number;
        mobile: string;
        orientation: string;
        display: string;
        size: string;
    };

    constructor() {
        this.menuHeight = 60;
        this.isPortraitPhone = true;
        this.scroll = 0;
        this.scrollDir = 'down';
        this.viewport = {
            width: 412,
            height: 732,
            scale: 1,
            top: 0,
            vw: 412,
            vh: 732,
            aspect: 412 / 732,
            mobile: 'ios',
            orientation: 'portrait',
            display: 'phone',
            size: 'small',
        };
    }

    updateScroll(scroll: number, scrollDir: ScrollDir) {
        this.scroll = scroll;
        this.scrollDir = scrollDir;

        // nothing looks at 'hb-scroll' so leaving this out for now
        // const nuxtApp = useNuxtApp();
        // nuxtApp.$eventEmitter.emit('hb-scroll', {
        //     position: scroll,
        //     dir: scrollDir
        // });
    }

    // updateViewport(viewport) {
    //     this.viewport = viewport;
    //     this.isPortraitPhone = this.viewport.display === 'phone' && this.viewport.orientation === 'portrait';
    //     // console.log('viewport', this.viewport)
    //     this.$emit('hb-viewport', this.viewport);
    // }
    // refresh() {
    //     this.$emit('hb-viewport', this.viewport);
    //     this.$emit('hb-scroller-refresh');
    // }
    // preloadImages() {
    //     this.$emit('hb-preload-images', this.viewport);
    // }
    // getBoundingClientRect($el) {
    //     // console.log('called for ', $el)
    //     if ($el && typeof $el.getBoundingClientRect === 'function') {
    //         const bb = $el.getBoundingClientRect();
    //         const ret =
    //             this.viewport.height !== this.viewport.vh
    //                 ? {
    //                       top: Math.round((bb.top - this.viewport.top) / this.viewport.scale),
    //                       bottom: Math.round((bb.bottom - this.viewport.top) / this.viewport.scale),
    //                       height: Math.round(bb.height / this.viewport.scale),
    //                       width: Math.round(bb.width / this.viewport.scale),
    //                       y: Math.round(bb.top / this.viewport.scale - this.viewport.top)
    //                   }
    //                 : bb;
    //         // console.log('viewports', this.viewport, ret)
    //         return ret;
    //     }
    //     return {
    //         top: 0,
    //         bottom: 0,
    //         height: 0,
    //         width: 0,
    //         y: 0
    //     };
    // }
    // // is the element in view
    // inView(bb) {
    //     // console.log('in view', bb, ret)
    //     if (this.viewport.height < 1) return false;
    //     const ret = bb.top <= this.viewport.height && bb.bottom >= 0;
    //     return ret;
    // }
    // // is the element close to be in view
    // upcoming(bb) {
    //     // console.log('upcoming', bb, ret)
    //     if (this.viewport.height < 1) return false;
    //     const ret = bb.top <= this.viewport.height * 2 && bb.bottom - this.viewport.height >= 0;
    //     return ret;
    // }
    // underMenu(bb) {
    //     return bb.top <= 0 && bb.bottom >= this.menuHeight;
    // }
    // intersect(bb, y, height) {
    //     return bb.top <= y + height && bb.bottom >= y;
    // }
}

export default defineNuxtPlugin((nuxtApp) => {
    const hbScroller = new HBScroller();
    nuxtApp.provide('hbScroller', hbScroller);

    // export default defineNuxtPlugin((nuxtApp) => {
    //     let emitter = new WoomEventEmitter();

    //     nuxtApp.provide('eventEmitter', emitter);
    // });
});
