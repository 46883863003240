import { createI18n } from 'vue-i18n';
// import { createI18n } from 'vue-i18n/dist/vue-i18n.mjs';
// 'vue-i18n': 'vue-i18n/dist/vue-i18n.runtime.esm-bundler.js'

import da from '../lang/da.js';
import de from '../lang/de.js';
import en from '../lang/en.js';
import fr from '../lang/fr.js';
import it from '../lang/it.js';
import nl from '../lang/nl.js';
import pl from '../lang/pl.js';
import sv from '../lang/sv.js';

const messages = {
    ...da,
    ...de,
    ...en,
    ...fr,
    ...it,
    ...nl,
    ...pl,
    ...sv,
};

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    messages,
});

export type LocaleType = typeof i18n.global.locale.value;
export type I18nType = typeof i18n;

export default defineNuxtPlugin(() => {
    // const { vueApp } = nuxtApp;
    // const t = () => (key:any, args:any) => {
    //     return i18n.global.t(key, args)
    //   };
    //   const tc = () => (key, num = 0, options = {}) => {
    //     i18n.global.
    //     return i18n.global.tc (key, num, options)
    //   };
    // const trans = (key, args, options) => {
    // }
    // vueApp.use(i18n);
    // nuxtApp.provide('i18n', i18n);
});
