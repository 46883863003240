export const localeCodes = ["de_AT","de_DE","de_CH","fr_CH","it_CH","en_US","fr_FR","it_IT","sv_SE","da_DK","nl_NL","en_INT"]

export const localeMessages = {
  "de_AT": [],
  "de_DE": [],
  "de_CH": [],
  "fr_CH": [],
  "it_CH": [],
  "en_US": [],
  "fr_FR": [],
  "it_IT": [],
  "sv_SE": [],
  "da_DK": [],
  "nl_NL": [],
  "en_INT": [],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../i18n.config.ts?hash=45ad88ff&config=1" /* webpackChunkName: "i18n_config_45ad88ff" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"de_AT","iso":"de-at","name":"�sterreich","files":[],"hashes":[],"types":[]}),Object({"code":"de_DE","iso":"de-de","name":"Deutschland","files":[],"hashes":[],"types":[]}),Object({"code":"de_CH","iso":"de-ch","name":"Schweiz","files":[],"hashes":[],"types":[]}),Object({"code":"fr_CH","iso":"fr-ch","name":"Suisse","files":[],"hashes":[],"types":[]}),Object({"code":"it_CH","iso":"it-ch","name":"Svizzera","files":[],"hashes":[],"types":[]}),Object({"code":"en_US","iso":"en-us","name":"United States","files":[],"hashes":[],"types":[]}),Object({"code":"fr_FR","iso":"fr-fr","name":"France","files":[],"hashes":[],"types":[]}),Object({"code":"it_IT","iso":"it-it","name":"Italia","files":[],"hashes":[],"types":[]}),Object({"code":"sv_SE","iso":"sv","name":"Sweden","files":[],"hashes":[],"types":[]}),Object({"code":"da_DK","iso":"da","name":"Denmark","files":[],"hashes":[],"types":[]}),Object({"code":"nl_NL","iso":"nl","name":"Netherlands","files":[],"hashes":[],"types":[]}),Object({"code":"en_INT","iso":"en","name":"International","files":[],"hashes":[],"types":[]})]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"de_AT","iso":"de-at","name":"�sterreich","files":[],"hashes":[],"types":[]}),Object({"code":"de_DE","iso":"de-de","name":"Deutschland","files":[],"hashes":[],"types":[]}),Object({"code":"de_CH","iso":"de-ch","name":"Schweiz","files":[],"hashes":[],"types":[]}),Object({"code":"fr_CH","iso":"fr-ch","name":"Suisse","files":[],"hashes":[],"types":[]}),Object({"code":"it_CH","iso":"it-ch","name":"Svizzera","files":[],"hashes":[],"types":[]}),Object({"code":"en_US","iso":"en-us","name":"United States","files":[],"hashes":[],"types":[]}),Object({"code":"fr_FR","iso":"fr-fr","name":"France","files":[],"hashes":[],"types":[]}),Object({"code":"it_IT","iso":"it-it","name":"Italia","files":[],"hashes":[],"types":[]}),Object({"code":"sv_SE","iso":"sv","name":"Sweden","files":[],"hashes":[],"types":[]}),Object({"code":"da_DK","iso":"da","name":"Denmark","files":[],"hashes":[],"types":[]}),Object({"code":"nl_NL","iso":"nl","name":"Netherlands","files":[],"hashes":[],"types":[]}),Object({"code":"en_INT","iso":"en","name":"International","files":[],"hashes":[],"types":[]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
