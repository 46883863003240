export default {
    en: {
        'x-product-option-size': 'Select Size',
        'x-product-description': 'Product details',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Your bike',
                label: 'Select your bike',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'incl. VAT plus shipping',
            plusCustomFees: 'Plus customs fees where applicable*',
            plusCustomFeesLink: 'https://help.woombikes.com/article/589-are-customs-fees-charged-for-orders-placed-outside-of-the-eu',
            color: 'Colour',
            fits: 'Compatible with',
            position: 'Position',
            edition: 'Edition',
            language: 'Language',
            size: 'Size',
            nameField: 'Name',
            sizes: {
                guide: 'Size Guide',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom ORIGINAL 1+ (from Nov. 2020), 2, 3',
                    m: 'ORIGINAL 4, 5 and OFF + OFF AIR 4',
                    l: 'ORIGINAL 6 and OFF + OFF AIR 5, 6 and UP 5, 6',
                },
            },
            variant: {
                front: 'front',
                rear: 'rear',
                left: 'left',
                right: 'right',
                en: 'English',
                de: 'German',
            },
            estimateDeliveryDate: 'Est. delivery date',
            // TODO: see https://vue-i18n.intlify.dev/guide/advanced/component.html
            // shippingPeriod: 'Available for pre-order and estimated to ship by <b>{date}</b>.',
            quantity: 'One item in Stock | {n} items in Stock',
            reviews: 'No reviews | One review | {n} reviews',
            estimatedShipping: 'Estimated shipping',
            estimatedTaxes: 'Estimated taxes',
            estimatedTotal: 'Estimated total',
            availability: {
                not_for_sale: 'This product is not for sale in this region.',
                preorder: {
                    short: 'Not in stock but available to pre-order.',
                    long: 'Available for pre-order and estimated to ship by <b>{date}</b>',
                    line: 'Expected to ship by {date}',
                },

                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
              ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'This product is not available in this region',
                notify_me: 'Enter your e-mail address to be the first to find out when this item is back in stock.',
                out_of_stock: 'Out of stock',
                weeks: '{period} Weeks',
            },
            detail: {
                bike: 'Size',
                weight: 'Weight',
                age: 'Age',
                height: 'Height',
                features: 'Top features',
                years: 'years',
            },
            geometry: {
                height: 'Height',
                label: 'Bike geometry',
                wheel: {
                    size: 'Tyre/wheel size',
                    base: 'Wheelbase',
                },
                handlebar: {
                    height: 'Cockpit/handlebar height',
                    adjust: 'Handlebar adjustment',
                },
                steering: {
                    angle: 'Steering angle',
                },
                top_tube: {
                    horizontal: 'Horizontal top tube length',
                },
                seat_tube: {
                    angle: 'Seat tube angle',
                },
                saddle: {
                    height: {
                        min: 'Min. saddle height',
                        max: 'Max. saddle height',
                    },
                },
                crank: {
                    length: 'Crank arm length',
                },
                standover: {
                    height: 'Stand-over height',
                },
            },
            specs: {
                label: 'Specifications',
            },
            configurator: {
                added: 'Check! The {product} has successfully coasted into your shopping cart',
                cart: 'proceed to cart',
                extras: 'Here are some other products that might interest you',
            },
            matchingProducts: 'Here are some products to match',
        },
        free: 'Free',
        tip: 'Tip',
        cart: {
            add: 'Add to cart',
            add_short: 'Add',
            add_preorder: 'Add to cart (Pre-order)',
            options: 'Options',
            yourCart: 'Your cart',
            viewCart: 'View cart & Checkout',
            tell_me_first: 'Tell me first!',
            checkout: 'Checkout',
            reviewCart: 'Review your cart',
            empty: 'Your cart is empty.\nFind the right bike for your child.',
            total: 'Your total',
            subtotal: 'Subtotal',
            items: 'empty | 1 item | {n} items',
            shipping: 'Shipping',
            additionalShippingCharges: 'Additional shipping charges may be applied at the checkout for oversized items',
            needHelp: 'Need help?',
            productUnavailable: 'Sorry, this product is unavailable at the moment.',
            maxQuantityReached: 'Sorry, the maximum quantity available for this product is {quantity}.',
            limitedQuantityAvailable: 'You have {currentQuantity} in your cart, but we only have {quantityAvailable} available at the moment.',
            couldNotAdd: 'Could not add product to cart.',
            limit: 'Order limit reached',
        },
        forms: {
            validation: {
                required: 'This field is required.',
                email: 'A valid e-mail address is required.',
                right: 'The following fields contain errors: {fields}',
                age: 'Please provide us with a valid age.',
                number: 'A number is required in this field.',
                select: 'Please make a selection.',
                file: 'Please select a file for upload.',
                consent: 'We would need your consent to proceed.',
            },
        },
        landing: {
            selector: {
                country: 'Country',
                language: 'Language',
                button: 'Go to website',
            },
        },
        bike: {
            bike: 'Bike',
            years: 'years',
            child: 'Child',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Discover the woom ORIGINAL bikes',
                off_off_air: 'Discover mountain bikes',
                up: 'Discover e-mountain bikes',
                now: 'Discover the woom NOW',
            },
        },
        bikefinder: {
            link: 'Find the right bike',
            years: 'years',
            how_it_works: 'How does that work?',
            next: 'Next',
            back: 'Back',
            see_results: 'See results',
            back_to_start: 'Start over',
            share: 'Share results',
            height: {
                small: 'small',
                medium: 'average',
                large: 'tall',
            },
            skill: {
                beginner: 'Beginner',
                advanced: 'Advanced',
            },
            gender: {
                boy: 'boy',
                girl: 'girl',
            },
        },
        article: {
            readmore: 'Read more',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
            count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
            and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
            nowLaunch: {
                title: 'Get ready.\nTo meet the bike that changes everything.',
                ctaLabel: 'Learn more',
                ctaLink: 'newslettersubscription',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Days',
                    },
                    hours: {
                        singular: 'Hour',
                        plural: 'Hours',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Second',
                        plural: 'Seconds',
                    },
                },
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Sign me up',
            },
            copyright: 'Copyright © 2021 woom GmbH. All rights reserved.',
            contact: 'Contact',
        },
        error: {
            homepage: 'Go to homepage',
            404: `Sorry, we can't find that page.
    You might have used an invalid link or the page may have been removed.`,
            '5xx': `Uuuuups!
    There is something wrong with our servers.
    We will be back soon.`,
        },
    },

    'en-us': {
        'x-product-option-size': 'Select Size',
        'x-product-description': 'Product details',
        selector: {
            original: {
                opt: {
                    0: 'woom 1',
                    1: 'woom 1 PLUS',
                    2: 'woom 2',
                    3: 'woom 3',
                    4: 'woom 4',
                    5: 'woom 5',
                    6: 'woom 6',
                },
            },
        },
        product: {
            tax: 'Price',
            plusCustomFees: '',
            plusCustomFeesLink: '',
            color: 'Color',
            fits: 'Compatible with',
            position: 'Position',
            edition: 'Edition',
            language: 'Language',
            size: 'Size',
            nameField: 'Name',
            sizes: {
                guide: 'Size Guide',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom ORIGINAL 1+ (from Nov. 2020), 2, 3',
                    m: 'ORIGINAL 4, 5 and OFF + OFF AIR 4',
                    l: 'ORIGINAL 6 and OFF + OFF AIR 5, 6 and UP 5, 6',
                },
            },
            variant: {
                front: 'front',
                rear: 'rear',
                left: 'left',
                right: 'right',
                en: 'English',
                de: 'German',
            },
            estimateDeliveryDate: 'Est. delivery date',
            // shippingPeriod: 'Available for pre-order and estimated to ship by <b>{date}</b>.',
            quantity: 'One item in Stock | {n} items in Stock',
            reviews: 'No reviews | One review | {n} reviews',
            estimatedShipping: 'Estimated shipping',
            estimatedTaxes: 'Estimated taxes',
            estimatedTotal: 'Estimated total',
            availability: {
                not_for_sale: 'This product is not for sale in this region.',
                preorder: {
                    short: 'Not in stock but available to pre-order.',
                    long: 'Available for pre-order and estimated to ship by <b>{date}</b>',
                    line: 'Expected to ship by {date}',
                },

                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
              ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'This product is not available in this region',
                notify_me: 'Enter your e-mail address to be the first to find out when this item is back in stock.',
                out_of_stock: 'Out of stock',
                weeks: '{period} Weeks',
            },
            detail: {
                bike: 'Size',
                weight: 'Weight',
                age: 'Age',
                height: 'Height',
                features: 'Top features',
                years: 'years',
            },
            geometry: {
                height: 'Height',
                label: 'Bike geometry',
                wheel: {
                    size: 'Tire/wheel size',
                    base: 'Wheelbase',
                },
                handlebar: {
                    height: 'Handlebar height',
                    adjust: 'Handlebar adjustment',
                },
                steering: {
                    angle: 'Steering angle',
                },
                top_tube: {
                    horizontal: 'Horizontal top tube length',
                },
                seat_tube: {
                    angle: 'Seat tube angle',
                },
                saddle: {
                    height: {
                        min: 'Min. saddle height',
                        max: 'Max. saddle height',
                    },
                },
                crank: {
                    length: 'Crank arm length',
                },
                standover: {
                    height: 'Stand-over height',
                },
            },
            specs: {
                label: 'Specifications',
            },
            configurator: {
                added: 'Check! The {product} has successfully coasted into your shopping cart',
                cart: 'proceed to cart',
                extras: 'Here are some other products that might interest you',
            },
            matchingProducts: 'Here are some products to match',
        },
        free: 'Free',
        tip: 'Tip',
        cart: {
            add: 'Add to cart',
            add_short: 'Add',
            options: 'Options',
            yourCart: 'Your cart',
            viewCart: 'View cart & Checkout',
            tell_me_first: 'Tell me first!',
            checkout: 'Checkout',
            reviewCart: 'Review your cart',
            empty: 'Your cart is empty.\nFind the right bike for your child.',
            total: 'Your total',
            subtotal: 'Subtotal',
            items: 'empty | 1 item | {n} items',
            shipping: 'Shipping',
            additionalShippingCharges: 'Additional shipping charges may be applied at the checkout for oversized items',
            needHelp: 'Need help?',
            productUnavailable: 'Sorry, this product is unavailable at the moment.',
            maxQuantityReached: 'Sorry, the maximum quantity available for this product is {quantity}.',
            limitedQuantityAvailable: 'You have {currentQuantity} in your cart, but we only have {quantityAvailable} available at the moment.',
            couldNotAdd: 'Could not add product to cart.',
        },
        forms: {
            validation: {
                required: 'This field is required.',
                email: 'A valid e-mail address is required.',
                right: 'The following fields contain errors: {fields}',
                age: 'Please provide us with a valid age.',
                number: 'A number is required in this field.',
                select: 'Please select an option.',
                file: 'Please select a file for upload.',
                consent: 'We would need your consent to proceed.',
            },
        },
        landing: {
            selector: {
                country: 'Country',
                language: 'Language',
                button: 'Go to website',
            },
        },
        bike: {
            bike: 'Bike',
            years: 'years',
            child: 'Child',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Discover the woom ORIGINAL bikes',
                off_off_air: 'Discover mountain bikes',
                up: 'Discover e-mountain bikes',
                now: 'Discover the woom NOW',
            },
        },
        bikefinder: {
            link: 'Find the right bike',
            years: 'years',
            how_it_works: 'How does that work?',
            next: 'Next',
            back: 'Back',
            see_results: 'See results',
            back_to_start: 'Start over',
            share: 'Share results',
            height: {
                small: 'small',
                medium: 'average',
                large: 'tall',
            },
            skill: {
                beginner: 'Beginner',
                advanced: 'Advanced',
            },
            gender: {
                boy: 'boy',
                girl: 'girl',
            },
        },
        article: {
            readmore: 'Read more',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
            count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
            and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
            nowLaunch: {
                title: 'Get ready.\nTo meet the bike that changes everything.',
                ctaLabel: 'Learn more',
                ctaLink: 'newslettersubscription',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Days',
                    },
                    hours: {
                        singular: 'Hour',
                        plural: 'Hours',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Second',
                        plural: 'Seconds',
                    },
                },
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Sign me up',
            },
            copyright: 'Copyright © 2021 woom GmbH. All rights reserved.',
            contact: 'Contact',
        },
        error: {
            homepage: 'Go to homepage',
            404: `Sorry, we can't find that page.
    You might have used an invalid link or the page may have been removed.`,
            '5xx': `Uuuuups!
    There is something wrong with our servers.
    We will be back soon.`,
        },
    },
};
