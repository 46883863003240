export default {
    nl: {
        'x-product-option-size': 'Maat selecteren',
        'x-product-description': 'Productdetails',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Je woom-fiets',
                label: 'Kies je woom-fiets',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'incl. btw, excl. verzendkosten',
            plusCustomFees: 'Plus customs fees where applicable*',
            plusCustomFeesLink: 'https://help.woombikes.com/article/589-are-customs-fees-charged-for-orders-placed-outside-of-the-eu',
            color: 'Kleur',
            fits: 'geschikt voor',
            position: 'positie',
            edition: 'model',
            language: 'Taal',
            size: 'Hoogte',
            nameField: 'Name',
            sizes: {
                guide: 'Maatinfo',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                Fietsstandaarden: {
                    s: 'woom ORIGINAL 1 PLUS (vanaf november 2020), woom ORIGINAL 2 & 3',
                    m: 'woom ORIGINAL 4 & 5, woom OFF/OFF AIR 4',
                    l: 'woom ORIGINAL 6, woom OFF/OFF AIR 5 & 6, woom UP 5 & 6',
                },
            },
            variant: {
                front: 'voorkant',
                rear: 'achterkant',
                left: 'links',
                right: 'rechts',
                en: 'Engels',
                de: 'Duits',
            },
            estimateDeliveryDate: 'verwachte bezorgdatum',
            // shippingPeriod: 'Momenteel niet op voorraad, pre-order is mogelijk verwachte bezorgdatum <b>{date}</b>.',
            quantity: 'Eén artikel op voorraad | {n} artikelen op voorraad',
            reviews: 'Geen beoordelingen | Eén beoordeling | {n} beoordelingen',
            estimatedShipping: 'verwachte verzendkosten',
            estimatedTaxes: 'verwachte belasting',
            estimatedTotal: 'verwacht totaalbedrag',
            availability: {
                not_for_sale: 'Dit product wordt niet te koop aangeboden in deze regio.',
                preorder: {
                    short: 'Momenteel niet op voorraad, pre-order mogelijk.',
                    long: 'Momenteel niet op voorraad, pre-order mogelijk.\nVerwachte bezorgdatum: <b>{date}</b>',
                    line: 'Expected to ship by {date}',
                },

                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
              ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'Dit product is niet beschikbaar in deze regio.',
                notify_me: 'Voer je e-mailadres in, dan informeren wij je als eerste wanneer dit artikel weer leverbaar is.',
                out_of_stock: 'Uitverkocht',
                weeks: '{period} weken',
            },
            detail: {
                bike: 'fietsmaat',
                weight: 'gewicht',
                age: 'leeftijd',
                height: 'Hoogte',
                features: 'Bijzonderheden',
                years: 'jaar',
            },
            geometry: {
                height: 'Hoogte',
                label: 'Maten',
                wheel: {
                    size: 'banden/wielmaat',
                    base: 'wielbasis',
                },
                handlebar: {
                    height: 'cockpit-/stuurhoogte',
                    adjust: 'verstelbaar stuur',
                },
                steering: {
                    angle: 'stuurhoek',
                },
                top_tube: {
                    horizontal: 'lengte horizontale bovenbuis',
                },
                seat_tube: {
                    angle: 'hoek zadelpen',
                },
                saddle: {
                    height: {
                        min: 'minimale zadelhoogte',
                        max: 'maximale zadelhoogte',
                    },
                },
                crank: {
                    length: 'lengte crank',
                },
                standover: {
                    height: 'Stand-over height',
                },
            },
            specs: {
                label: 'Specificaties',
            },
            configurator: {
                added: 'Check! De {product} is je winkelwagentje in gefietst.',
                cart: 'verder naar winkelwagentje',
                extras: 'Deze producten zijn misschien ook wel wat voor jou:',
            },
            matchingProducts: 'Deze producten passen erbij:',
        },
        free: 'gratis',
        tip: 'Tip',
        cart: {
            add: 'aan winkelwagentje toevoegen',
            add_short: 'toevoegen',
            add_preorder: 'Add to cart (Pre-order)',
            options: 'opties',
            yourCart: 'Jouw winkelwagentje',
            viewCart: 'winkelwagentje bekijken & aankoop afronden',
            tell_me_first: 'Hou mij op de hoogte!',
            checkout: 'Afrekenen',
            reviewCart: 'winkelwagentje controleren',
            empty: 'Je winkelwagentje is leeg.\nVind nu de perfecte fiets voor jouw kind!',
            total: 'Totaalbedrag',
            subtotal: 'Subtotaal',
            items: 'leeg | 1 artikel | {n} artikelen',
            shipping: 'Verzending',
            additionalShippingCharges: 'Voor grote artikelen kunnen er aanvullende verzendkosten worden verrekend voor het afrekenen.',
            needHelp: 'Vragen?',
            productUnavailable: 'Sorry, dit product is momenteel niet verkrijgbaar.',
            maxQuantityReached: 'Sorry, van dit product zijn maximaal {quantity} exemplaren verkrijgbaar.',
            limitedQuantityAvailable:
                'Je hebt {currentQuantity} exemplaren aan je winkelwagentje toegevoegd, maar er zijn er momenteel slechts {quantityAvailable} verkrijgbaar.',
            couldNotAdd: 'Het product kon niet aan je winkelwagentje worden toegevoegd.',
            limit: 'Bestelmaximum bereikt',
        },
        forms: {
            validation: {
                required: 'Dit veld mag niet leeg zijn.',
                email: 'Voer een geldig e-mailadres in.',
                right: 'De volgende velden bevatten fouten: {fields}',
                age: 'Voer een geldige leeftijd in.',
                number: 'Voer een cijfer in.',
                select: 'Maak je keuze.',
                file: 'Selecteer een bestand om te uploaden.',
                consent: 'We hebben je toestemming nodig.',
            },
        },
        landing: {
            selector: {
                country: 'Land',
                language: 'Taal',
                button: 'Website oproepen',
            },
        },
        bike: {
            bike: 'Bike',
            years: 'years',
            child: 'Child',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Discover the woom ORIGINAL bikes',
                off_off_air: 'Discover mountain bikes',
                up: 'Discover e-mountain bikes',
                now: 'Discover the woom NOW',
            },
        },
        bikefinder: {
            link: 'Vind de perfecte fiets.',
            years: 'leeftijd',
            how_it_works: 'Hoe werkt dat?',
            next: 'verder',
            back: 'terug',
            see_results: 'resultaten tonen',
            back_to_start: 'bij het begin beginnen',
            share: 'resultaten delen',
            height: {
                small: 'klein',
                medium: 'gemiddeld',
                large: 'groot',
            },
            skill: {
                beginner: 'beginner',
                advanced: 'gevorderd',
            },
            gender: {
                boy: 'jongen',
                girl: 'meisje',
            },
        },
        article: {
            readmore: 'meer informatie',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
            count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
            and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
            nowLaunch: {
                title: 'Get ready.\nTo meet the bike that changes everything.',
                ctaLabel: 'Learn more',
                ctaLink: 'newslettersubscription',
                countdown: {
                    days: {
                        singular: 'Day',
                        plural: 'Days',
                    },
                    hours: {
                        singular: 'Hour',
                        plural: 'Hours',
                    },
                    minutes: {
                        singular: 'Minute',
                        plural: 'Minutes',
                    },
                    seconds: {
                        singular: 'Second',
                        plural: 'Seconds',
                    },
                },
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'meer informatie',
            },
            copyright: 'Copyright © 2021 woom GmbH. Alle rechten voorbehouden.',
            contact: 'Contact',
        },
        error: {
            homepage: 'Startpagina openen',
            404: `Sorry, we konden de pagina niet vinden.
          Het kan zijn dat je een ongeldige link hebt geopend of de pagina is verwijderd.`,
            '5xx': `Oeps! Er wordt momenteel aan de server gewerkt. We zijn er binnenkort weer.`,
        },
    },
};
