export default {
    pl: {
        'x-product-option-size': 'Wybierz rozmiar',
        'x-product-description': 'Szczegóły produktu',
        google: {
            form: {
                addFile: 'Add File',
                clearFiles: 'clear',
                noFiles: 'No files selected',
                submit: 'submit',
                previous: 'previous',
                next: 'next',
            },
        },
        selector: {
            original: {
                key: 'Your bike',
                label: 'Select your bike model',
                opt: {
                    0: 'woom ORIGINAL 1',
                    1: 'woom ORIGINAL 1 PLUS',
                    2: 'woom ORIGINAL 2',
                    3: 'woom ORIGINAL 3',
                    4: 'woom ORIGINAL 4',
                    5: 'woom ORIGINAL 5',
                    6: 'woom ORIGINAL 6',
                },
            },
        },
        product: {
            tax: 'z VAT plus koszty wysyłki',
            color: 'Kolor',
            fits: 'pasuje do',
            position: 'Position',
            edition: 'Język',
            language: 'Language',
            size: 'Rozmiar',
            nameField: 'Nazwa',
            sizes: {
                guide: 'Opis rozmiaru',
                s: 'Small',
                m: 'Medium',
                l: 'Large',
                xl: 'X-Large',
                'adult-s': 'S',
                'adult-m': 'M',
                'adult-l': 'L',
                'adult-xl': 'XL',
                5: '100/110',
                6: '110/120',
                7: '120/130',
                10: '130/140',
                11: '140/150',
                14: '150/160',
                'leggie-kickstand': {
                    s: 'woom 1 PLUS (od listopada 2020), woom 2 & 3',
                    m: 'woom 4 & 5, woom OFF/OFF AIR 4',
                    l: 'woom 6, woom OFF/OFF AIR 5 & 6, woom UP 5 & 6',
                },
            },
            variant: {
                front: 'przód',
                rear: 'tył',
                left: 'lewa',
                right: 'prawa',
                en: 'Angielski',
                de: 'Niemiecki',
            },
            estimateDeliveryDate: 'Przewidywana data dostawy',
            // shippingPeriod: 'Obecnie brak na stanie, istnieje możliwość zamówienia. Przewidywana data dostawy: {date}',
            quantity: 'Jedna sztuka na stanie. | Liczba sztuk na stanie: {n}.',
            reviews: 'Brak ocen | Jedna ocena | Liczba ocen: {n}',
            estimatedShipping: 'Przewidywane koszty wysyłki',
            estimatedTaxes: 'Przewidywane podatki',
            estimatedTotal: 'Przewidywana kwota łączna',
            availability: {
                not_for_sale: 'Ten produkt nie jest w sprzedaży w tym regionie.',
                preorder: {
                    short: 'Obecnie brak na stanie, istnieje możliwość zamówienia.',
                    long: 'Obecnie brak na stanie, istnieje możliwość zamówienia.\nPrzewidywany termin dostawy: {date}',
                    line: 'Przewidywany termin dostawy: {date}',
                },
                comingsoon: {
                    short: 'Not in stock.',
                    long: `Not in stock.
                                        ​​​​​​Order window expected to re-open in {date}`,
                },
                not_in_this_region: 'Ten produkt nie jest dostępny w tym regionie.',
                notify_me: 'Podaj adres e-mail, aby w pierwszej kolejności dowiedzieć się, kiedy produkt będzie znów dostępny.',
                out_of_stock: 'Wyprzedane',
                weeks: '{period} tyg.',
            },
            detail: {
                bike: 'Rowerek dziecięcy',
                weight: 'Waga',
                age: 'Wiek',
                height: 'Rozmiar',
                features: 'Cechy szczególne',
                years: 'Wiek',
            },
            geometry: {
                height: 'Rozmiar',
                label: 'Geometria ramy',
                wheel: {
                    size: 'Rozmiar opony/koła',
                    base: 'Rozstaw osi',
                },
                handlebar: {
                    height: 'Wysokość kokpitu / chwytów kierownicy',
                    adjust: 'Regulacja pochylenia kierownicy',
                },
                steering: {
                    angle: 'Kąt główki ramy',
                },
                top_tube: {
                    horizontal: 'Efektywna długość rury górnej',
                },
                seat_tube: {
                    angle: 'Kąt rury podsiodłowej',
                },
                saddle: {
                    height: {
                        min: 'Minimalna wysokość siodełka',
                        max: 'Maksymalna wysokość siodełka',
                    },
                },
                crank: {
                    length: 'Długość ramion korby',
                },
            },
            specs: {
                label: 'Specyfikacje',
            },
            configurator: {
                added: 'Bingo! {product} wjechał do Twojego koszyka.',
                cart: 'do koszyka',
                extras: 'Produkty, które mogą Cię również zainteresować:',
            },
            matchingProducts: 'Pasujące produkty:',
        },
        free: 'bezpłatnie',
        tip: 'Porada',
        cart: {
            add: 'Dodaj do koszyka',
            add_preorder: 'Dodaj do koszyka',
            add_short: 'Dodaj',
            options: 'Opcje',
            yourCart: 'Twój koszyk',
            viewCart: 'Zobacz koszyk i zakończ zakup',
            tell_me_first: 'Powiadom mnie w pierwszej kolejności!',
            checkout: 'Finalizuj zamówienie',
            reviewCart: 'Sprawdź koszyk',
            empty: 'Twój koszyk jest pusty.\nZnajdź odpowiedni rower dla swojego dziecka!',
            total: 'Łączna kwota',
            subtotal: 'Kwota pośrednia',
            items: 'Pusty | 1 produkt | Liczba produktów: {n}',
            shipping: 'Wysyłka',
            additionalShippingCharges:
                'W przypadku produktów ponadwymiarowych podczas finalizacji zamówienia mogą zostać naliczone dodatkowe koszty wysyłki.',
            needHelp: 'Masz pytania?',
            productUnavailable: 'Niestety ten produkt jest chwilowo niedostępny.',
            maxQuantityReached: 'Niestety mamy jedynie {quantity} szt. tego produktu.',
            limitedQuantityAvailable: 'W koszyku masz {currentQuantity} szt. tego produktu, ale na stanie mamy jedynie {quantityAvailable} szt.',
            couldNotAdd: 'Nie udało się dodać tego produktu do koszyka.',
            limit: 'Order limit reached',
        },
        forms: {
            validation: {
                required: 'To pole nie może być puste.',
                email: 'Wymagany jest ważny adres e-mail.',
                right: 'Następujące pola zawierają błędy: {fields}',
                age: 'Podaj prawidłowy wiek.',
                number: 'Podaj liczbę.',
                select: 'Wybierz opcję.',
                file: 'Wybierz plik do przesłania.',
                consent: 'Wymagana jest Twoja zgoda.',
            },
        },
        landing: {
            selector: {
                country: 'Kraj',
                language: 'Język',
                button: 'Otwórz witrynę',
            },
        },
        bike: {
            bike: 'Rower',
            years: 'Wiek',
            child: 'Dziecko',
        },
        discover_bikes: {
            open_bike_category: {
                original: 'Odkryj rowery woom ORIGINAL',
                off_off_air: 'Odkryj rowery górskie',
                up: 'Discover e-mountain bikes',
            },
        },
        bikefinder: {
            link: 'Znajdź odpowiedni rozmiar roweru',
            years: 'Wiek',
            how_it_works: 'Jak to działa?',
            next: 'Dalej',
            back: 'Powrót',
            see_results: 'Wyświetl wyniki',
            back_to_start: 'Zacznij od początku',
            share: 'Udostępnij wyniki',
            height: {
                small: 'Mały',
                medium: 'średnie',
                large: 'Duży',
            },
            skill: {
                beginner: 'Początkujący',
                advanced: 'zaawansowane',
            },
            gender: {
                boy: 'Chłopiec',
                girl: 'Dziewczynka',
            },
        },
        article: {
            readmore: 'Dowiedz się więcej',
        },
        campaign: {
            nameOrTitle: 'Name or Title',
            age: 'Age',
            ageoption: 'Age {age}',
            search: 'Filter Results',
            noresults: 'No Entries found',
            loadmore: 'Load More',
            btn: {
                search: 'Search',
                clear: 'Clear',
            },
            gdg: {
                headline: `Ride your bike, eat ice cream,
            count your kilometres & make a difference!`,
                headlineMillion: `Ride your bike, eat ice cream,
            and win great prizes!`,
                headlineEnded: 'Ride for a Brighter Tomorrow',
                button: 'Take part now!',
                woomSupportsUnicef: 'supports',
                unicefInfo: 'UNICEF does not endorse any company, brand, product or service.',
            },
        },
        footer: {
            newsletter: {
                'sign-me-up': 'Zapisz się do newslettera',
            },
            copyright: 'Copyright © 2021 woom GmbH. Wszystkie prawa zastrzeżone.',
            contact: 'Kontakt',
        },
        error: {
            homepage: 'Wyświetl stronę główną',
            404: `Niestety nie znaleziono poszukiwanej strony. 
                            Link może być nieprawidłowy albo strona została usunięta.`,
            '5xx': `Ups! 
                            Aktualnie trwają prace na serwerze. Wkrótce wrócimy!`,
        },
    },
};
