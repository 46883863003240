// https://nuxt.com/docs/guide/going-further/custom-routing#using-approuteroptions

import type { RouterConfig } from '@nuxt/schema';

const interop = (promise: Promise<any>) => {
    return promise.then((m) => m.default || m);
};

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    routes: (_routes) => {
        const routes = [
                //     {
                //     name: ROUTE_SHOPPING_CART,
                //     path: '/:langCountry/cart/:checkout?',
                //     component: () => interop(
                //       import('~/controller/ShoppingCart')),
                //     meta: { requiresAuth: false },
                //   },
                //   {
                //     name: 'policy',
                //     path: '/:lang([a-zA-Z]{2})/policy',
                //     component: () => interop(
                //       import('~/controller/CookiePolicy')),
                //     meta: { requiresAuth: false },
                //   },
                //   {
                //     name: 'content-switch',
                //     path: '/:lang([a-zA-Z]{2})/:slug',
                //     component: () => interop(
                //       import('../controller/ContentSwitch')),
                //     meta: { requiresAuth: false },
                //   },
                //   {
                //     name: 'preview-product',
                //     path: '/preview/product/:cms/:lang/:id',
                //     component: () => interop(
                //       import('../controller/ProductPreview')),
                //     meta: { requiresAuth: false },
                //   },
                //   {
                //     name: 'preview-home',
                //     path: '/preview/:cms/:lang/:id',
                //     component: () => interop(
                //       import('../controller/ContentPreview')),
                //     meta: { requiresAuth: false },
                //   },
                {
                    name: 'new-homepage',
                    path: '/homepage',
                    component: () => interop(import('~/controller/Homepage.vue')),
                    meta: { requiresAuth: false }
                },
                {
                    name: 'new-category',
                    path: '/category',
                    component: () => interop(import('~/controller/Category.vue')),
                    meta: { requiresAuth: false }
                },
                {
                    name: 'new-pdp',
                    path: '/pdp',
                    component: () => interop(import('~/controller/Pdp.vue')),
                    meta: { requiresAuth: false }
                },
                {
                    name: 'language-switch',
                    path: '/start/:lang([a-zA-Z]{2})?',
                    component: () => interop(import('~/controller/LanguageSwitcher.vue')),
                    meta: { requiresAuth: false },
                },
                {
                    name: 'home',
                    path: '/:langCountry([a-zA-Z]{2}_[a-zA-Z0-9]{2,3})/:slug*',
                    component: () => interop(import('~/controller/PageController/index.vue')),
                    meta: { requiresAuth: false },
                },
                {
                    name: 'root',
                    path: '/:lang([a-zA-Z]{2})?',
                    component: () => interop(import('~/controller/LanguageSwitcher.vue')),
                    meta: { requiresAuth: false },
                },
                {
                    name: 'unknown',
                    path: '/*',
                    component: () => interop(import('~/controller/LanguageSwitcher.vue')),
                    meta: { requiresAuth: false },
                },
            ];

        return routes;
    },
};
